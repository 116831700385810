import Base from './../base';

import { Link } from 'react-router-dom';

import { message, Tag } from 'antd';

import { FinancialYears, Branches, InvoiceNumbers } from './../';

import { DateUtils } from '../../utils';


class InvoiceNumber extends Base {

    constructor() {
        super();

        this.fields = [
            {
                field: 'category',
                caption: 'Category',
            },
            {
                type: 'reference-select',
                // model: Branches,
                field: 'branch_id',
                caption: 'Branch',
            },
            {
                type: 'reference-select',
                field: 'financial_year_id',
                // model: FinancialYears,
                caption: 'Financial Year',
            },

            {
                field: 'lastNumber',
                caption: 'Last Number',
            },

            {
                field: 'value',
                caption: 'Value',
            },

        ];

        // this.columns = ;
    }

    get getEndpoint() {
        return 'invoice-numbers';
    }

    get path() {
        return `invoice-numbers`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `invoice-number`;
    }

    disableAddModal() {
        return false;
    }

    disableAdd() {
        return false;
    }


    get columns() {
        return [
            {
                field: 'category',
                caption: 'Category',
            },
            {
                type: 'reference-select',
                // model: Branches,
                field: 'branch_id',
                caption: 'Branch',
            },
            {
                type: 'reference-select',
                field: 'financial_year_id',
                // model: FinancialYears,
                caption: 'Financial Year',
            },

            {
                field: 'lastNumber',
                caption: 'Last Number',
            },

            {
                field: 'value',
                caption: 'Value',
            },

        ];
    }

    /**
     * Get the Bill Number / OP Number / Registration Number
     * 
     * @param {*} param0 
     * @returns 
     */
    getNextNumber = async ({ category, menu, user, branch_id }) => {

        if (!user.branch_id) {
            message.error('No branch assigned');

            return {
                success: false
            }
        }

        var queries = [{
            field: 'branch_id',
            value: branch_id
        }]

        const { branches } = await Branches.get(queries);

        let current_date = DateUtils.getTime();

        var queries = [{
            field: 'start_date',
            operator: '>=',
            value: current_date
        }, {
            field: 'end_date',
            operator: '<=',
            value: current_date
        }]

        const result = await FinancialYears.get();

        let financial_years = result['financial-years'];

        let matching = financial_years.filter((item) => current_date >= item.start_date && current_date <= item.end_date);

        if (!matching.length) {

        }

        if (matching.length) {

            var queries = [{
                field: 'branch_id',
                value: branch_id
            }, {
                field: 'financial_year_id',
                value: matching[0].id
            }, {
                field: 'category',
                value: category
            }]

            const response = await InvoiceNumbers.get(queries)

            return response['invoice-numbers'][0];

        } else {

        }

    };


    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    Card = (record) => {
        return (
            <Link className="booking-card" to={`/collections/${record['id']}`}>
                <div className="card">
                    <h2 className="">{record.caption}</h2>

                    <h3 className="title">{record.description}</h3>

                    <p className="address">
                        Collected on {record.created_at}
                        <Tag color="magenta">{record['Consultant Name']}</Tag>
                    </p>
                </div>
            </Link>
        );
    };

}

export default InvoiceNumber;
