/**
 * All the date related activities should happen here 
 */

import moment from 'moment-timezone';

import { Timestamp } from 'firebase/firestore';



/**
 * 
 */

let dateFormat = 'DD/MM/YYYY';

let timeFormat = ' HH:mm DD/MM/YYYY'

let hourFormat = 'HH:mm a'

let timezone = 'Asia/Kolkata';

let datetimeFormat = 'YYYY-MM-DD HH:mm'

export default class DateUtils {

    /**
     * 
     * 
     * @param {*} branchTimezone 
     */
    static setTimezone = (branchTimezone) => {
        timezone = branchTimezone;
    }

    static getDate = (unix) => {

        return moment(unix).format('DD/MM/YYYY')
    }

    /**
     * Get the timestamp value
     * 
     * @returns 
     */
    static getTimestampValue = (value̦) => {

        return moment(value̦).valueOf();
    };

    static getFirebaseDate = (value) => {

        return moment(value, 'DD/MM/YYYY HH:mm a').toDate();
    }

    static displayFirestoreTime = (value, format) => {

        if (value) {

            return moment(value.toDate()).format(format || timeFormat);

        } else {

            return null;
        }
    }

    /**
     * 
     * 
     * ASDHI
     */
    static getFirebaseTimestamp = (value) => {

        if (moment.isMoment(value)) {

            let unix = moment(value).valueOf();

            return new Timestamp(unix / 1000, 1000)

        } else {

            let unix = moment(value, 'DD/MM/YYYY HH:mm a').valueOf();

            return new Timestamp(unix / 1000, 1000)

        }

    }

    /**
     * Get the firebase time
     * 
     * @returns 
     */
    static getTime = () => {

        return moment().valueOf();
    };

    /**
     * Gte formatted date in format YYYY-MM-DD HH:mm
     * 
     * @param {*} time 
     * @returns 
     */
    static getFormattedTimeDate = (time) => {

        return moment(time).format(datetimeFormat)
    };

    /**
     * 
     */
    static getStartOfDay = () => {

        return moment().startOf('day')
    }


    /**
     * Get 7 days from today
     */
    static getEndOfDay = () => {

        return moment().endOf('day');
    }

    /**
     * Get 7 days from today
     */
    static getEndOfWeek = () => {

        return moment().endOf('week');
    }

    /**
     * 
     * 
     * @param {*} time 
     * @returns 
     */
    static getFormattedTime = (time) => {

        return moment(time).format(timeFormat);
    };

    /**
     * 
     * 
     * @param {*} time 
     * @returns 
     */
    static getDisplayTime = (time) => {

        return moment.tz(time, timezone).format(timeFormat);
    };



    static today = () => {
        return moment.tz(timezone).valueOf();
    };

    static time = () => {

        return {
            current: moment.tz(timezone).valueOf(),
            present: moment.tz().valueOf(),
            notimezone: moment().valueOf(),
        }

    }

    static getDuration = (start, end) => {

        var duration = moment.duration(end.diff(start));

        return duration.asMinutes().toFixed(2);

    }

    static getPastTime = (start) => {

        var duration = moment.duration(moment().diff(start));

        return duration.asMinutes();

    }


    static getCreation = (field) => {

        let creation = {
            [field + '_at']: moment.tz(timezone).format('DD/MM/YYYY HH:mm'),
            [field + '_date']: moment.tz(timezone).startOf('day').valueOf(),
            [field + '_time']: moment.tz(timezone).valueOf(),
        };

        return {
            ...creation
        }
    }


    /**
     * From Firebase Timestamp
     * 
     * @param {*} param0 
     * @returns 
     */
    static fromFirebaseMoment = (time) => {

        let dt = time.toDate();

        return moment(dt);
        // return moment(time).tz(timezone).format(dateFormat) + ' ' + appointment_time
    }


    /**
     * From Firebase Timestamp
     * 
     * @param {*} param0 
     * @returns 
     */
    static fromFirebaseTime = ({ time }) => {

        let dt = time.toDate();

        return moment(dt).format('DD/MM/YYYY HH:mm a');
        // return moment(time).tz(timezone).format(dateFormat) + ' ' + appointment_time
    }


    /**
     * 
     * @param {*} param0 
     * @returns 
     */
    static appointmentTime = ({ appointment_date, appointment_time }) => {
        return moment(appointment_date).tz(timezone).format(dateFormat) + ' ' + appointment_time
    }

    static formatDate = (value) => {
        return moment(value).tz(timezone).format(dateFormat)
    }

    /**
     * 
     * @param {*} value 
     * @returns 
     */
    static formatTime = (value) => {
        return moment.tz(value, timezone).format(hourFormat)
    }

    /**
    * 
    * @param {*} value 
    * @returns 
    */
    static returnTime = (value) => {
        return moment(value).format(hourFormat)
    }


    static surgeTime = (time) => {

        return moment(time, "YYYY-MM-DDTHH:mm:ss.SSSSZ").utc().format('DD/MM/YYYY HH:mm a')
    }


    /**
     * Return the moment object of the integer , Usually used for datepicker
     * 
     * @param {*} time 
     * @returns 
     */
    static getMomentObject = (time) => {

        return moment(time, timezone);
    }



    /**
     * Return the moment object of the integer , Usually used for datepicker
     * 
     * @param {*} time 
     * @returns 
     */
    static getMomentInFormat = (time, format) => {

        return moment(time, format);
    }

    /**
     * Moment
     * 
     * @param {*} time 
     * @returns 
     */
    static getMoment = (time = null) => {

        return moment();
    }

}