/**
 * Component to input code
 */

import React, { useState, useEffect, useContext } from 'react';

import { Modal, Button } from 'antd';

import AceEditor from 'react-ace';

import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-github";

import { Scripts } from './../../models/';

import './script-input.scss';

const maxFontSize = 16;
const minFontSize = 10;
const SCRIPT_FONT_SIZE = 'SCRIPT_FONT_SIZE';
const DEFAULT_FONT_SIZE = 14;

/**
 * Generic List
 *
 * @param {*} param0
 */
function ScriptInput({ script_id, callback }) {

    const [loading, setLoading] = useState(false);

    const [value, setValue] = useState('');

    // 
    const [visible, setVisible] = useState(false);

    useEffect(() => {

        if (script_id) {
            loadScript(script_id);
        }

        // if(script_id)
        return () => {

        }

    }, [])

    /**
     * Load the script
     */
    function loadScript() {

        setLoading(true);

        Scripts.getRecord(script_id).then((result) => {

            setValue(result.value);

            setLoading(false);

        })
    }

    /**
     * On Submit of script
     */
    function onSubmit() {

        setLoading(true);

        let params = {
            value
        }

        if (script_id) {

            Scripts.update(script_id, params).then((result) => {

                callback({ script_id: script_id })

                setLoading(false);

            })

        } else {

            Scripts.add(params).then((result) => {

                callback({ script_id: result.id });

                setLoading(false);

            })
        }
    }

    /**
     * 
     * @param {*} script 
     */
    function handleChange(script) {

        setValue(script);
    }

    function onClose() {
        setVisible(false);
    }

    return (
        <section className="script-input">

            <Button size={'small'} onClick={() => { setVisible(true); }}>
                {script_id ? 'Edit' : 'Add'} Script
            </Button>

            {/* Add Modal */}
            <Modal width="80%" className="code-editor-modal" okText={'Submit'} title={'Code Editor'} footer={[
                <Button key="back" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>
                    Submit
                </Button>,
            ]} visible={visible} onCancel={onClose}>

                <AceEditor
                    mode={'javascript'}
                    // theme="github"
                    theme="monokai"
                    name="Code Editor"
                    width='100%'
                    // height='85vh'
                    // onLoad={this.onLoad}
                    onChange={handleChange}
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={value}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                    }}
                />
            </Modal>

            {/* Add Modal Ends */}
        </section>
    );
}

export default (ScriptInput);
