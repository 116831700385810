import React from 'react'

import "./App.scss";

import Registration from "./business/registration/registration"
import Success from './business/success/success';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";


import {
  RootApplication,
} from "@soxo/firebase-core";

import "antd/dist/antd.css";

import * as CustomComponents from './././business/'


let appSettings = {
  brandLogo: require(process.env.REACT_APP_BRAND_LOGO),
  // heroImage: require(process.env.REACT_APP_HERO_IMAGE),
  // footerLogo: require(process.env.REACT_APP_FOOTER_LOGO),
  // registerImage: require(process.env.REACT_APP_REGISTER_IMAGE),

  getToken: () => {
    return null;
  },
};

let CustomModels = {};

function App() {
  return (

    <RootApplication
      CustomComponents={CustomComponents}
      CustomModels={CustomModels}
      appSettings={appSettings}>

      <Route
        exact
        path="/"
        render={(routeParams) => {
          return <Registration />;
        }}
      />

      <Route path="/success">
        <Success />
      </Route>


    </RootApplication>
  )
}

export default App