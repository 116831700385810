/**
 * 
 * Store as an initial local store for the app
 * 
 * All the data that is needed accross the app like user, openProperties etc goes from here.
 * 
 */

import React, { useEffect, useState, createContext, useReducer } from 'react';


// import FirebaseUtils from './utils/firebase.utils';

import FirebaseUtils from './utils/firebase.utils';

import useDeviceDetect from './hooks/device-detect';

import ApiUtils from './utils/api.utils';

// import { initializeFirebasePushNotification } from './push-notification';

/**
 * Initial State to be stored
 * accross the app
 * 
 * For now maintaining only user data 
 * 
 * #todo Compare the setup with state manager utility 
 * 
 */
const initialState = {

    defaultBranch: {

    }
};

/**
 * Context for sharing state accross app
 */
export const GlobalContext = createContext(initialState);

let app = {};

/**
 *  Provider links the reducer and context
 */
export const GlobalProvider = ({ children, CustomModels, appSettings: settings }) => {

    console.log("Setting up store");

    const [branches, setBranches] = useState([]);

    const [state, dispatch] = useReducer(AppReducer, initialState)

    const { isMobile } = useDeviceDetect();

    useEffect(() => {

        bootApplication();

        return () => {

        };

    }, [])

    /**
     * 
     */
    function bootApplication() {

        if (process.env.REACT_APP_PRIMARY_BACKEND === 'firebase') {

            bootFirebaseApplication();

        } else {

            bootMainApplication();

        }
    }

    /**
     * 
     */
    function bootFirebaseApplication() {

        return FirebaseUtils.connect().then((app, database) => {

            app = app;

            return FirebaseUtils.getAuthStatus().then((result) => {
                // console.log(result);

                if (result) {

                    // initializeFirebasePushNotification(result);

                    let userInfo = {
                        dms: {},
                        locations: [],
                        ...result,
                        ...{ loggedCheckDone: true }
                    }

                    if (result.role && result.role.name === 'super') {

                        userInfo.isAdmin = true;

                    }


                    dispatch({ type: 'user', payload: userInfo });

                    localStorage.setItem('userInfo', JSON.stringify(userInfo));

                    // dispatch({ type: 'user', payload: { ...result, ...{ loggedCheckDone: true } } })
                } else {

                    dispatch({ type: 'user', payload: { ...{ loggedCheckDone: true } } })

                }
            }).catch((error) => {
                console.log(error);
            })
        })
    }


    function bootMainApplication() {

        ApiUtils.getAuthStatus({ settings }).then((result) => {

            if (result.statusCode === 401) {
                dispatch({ type: 'user', payload: { ...{ loggedCheckDone: true } } });
            } else {
                // console.log(result);

                let userInfo = {
                    ...result,
                    ...{ loggedCheckDone: true },
                    // isAdmin: true
                };

                dispatch({ type: 'user', payload: userInfo });
            }
        }).catch(() => {

            dispatch({ type: 'user', payload: { ...{ loggedCheckDone: true } } });

        });

    }


    // Store that is accessible accross the app
    // let store = { user: state.user, slot: state.slot, dispatch: dispatch, selectedLocation: state.selectedLocation }
    let store = {
        app: app,
        user: state.user,
        dispatch: dispatch,
        twilio: state.twilio,
        isMobile,
        branches,
        defaultBranch: state.defaultBranch,
        CustomModels: CustomModels
    };

    // console.log(CustomModels);

    return (<GlobalContext.Provider value={store}>
        {children}
    </GlobalContext.Provider>)

}

/**
 * Reducer for managing the state
 * 
 * @param state 
 * @param action 
 */
export const AppReducer = (state, action) => {

    switch (action.type) {


        case 'app':

            return { ...state, app: action.payload };

        case 'twilio':

            return { ...state, twilio: action.payload };

        case 'user':
            return { ...state, user: action.payload };

        case 'slot':
            return { ...state, user: action.payload };

        case 'branches':
            return { ...state, branches: action.payload };

        case 'defaultBranch':
            return { ...state, defaultBranch: action.payload };

        case 'CustomModels':
            return { ...state, defaultBranch: action.CustomModels };


        case 'selectedLocation':
            localStorage.selectedLocation = JSON.stringify(action.payload);
            return { ...state, selectedLocation: action.payload };

        default:
            return state;
    }
}