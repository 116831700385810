/**
 * Generic Edit Form create accepts an array of fields to update any resource
 */

import React, { useState, useEffect, useContext } from "react"

import { Typography, Button, message, Input, Upload, Space, Tag } from 'antd';

import { withRouter } from 'react-router-dom'

// import './generic-add.scss'

import XLSX from 'xlsx';

import FirebaseUtils from "../../../utils/firebase.utils";

import { UploadOutlined } from '@ant-design/icons';

import useDeviceDetect from "../../../hooks/device-detect";

import PlaceHolder from "../../../components/ui_elements/PlaceHolder";

import { GlobalContext } from "../../../Store";

const { Title } = Typography;

const { TextArea } = Input;

/**
 * Generic List 
 * 
 * @param {*} param0 
 */
function GenericUpload({ match, history, model, schema, collection, columns: cols }) {

    const [content, setContent] = useState({});

    const [records, setRecords] = useState([]);

    const [newparts, setNewparts] = useState({});

    const { isMobile } = useDeviceDetect();

    const [files, setFiles] = useState([])

    const { params } = match;

    const { city } = params;

    const { user } = useContext(GlobalContext);

    // const city = user.locations[0];

    const [loading, setLoading] = useState(false);

    const step = {
        fields: cols
    }

    useEffect(() => {

        setLoading(true);

        var queries = [];

        FirebaseUtils.getListing(city, queries, model, collection, model + 'Id').then((result) => {

            setRecords(result);

            var r = {};

            result[model].forEach((item) => {

                var key = findIndex(item);

                r[key] = item;
                // r[item[key]] = item;
            })

            setContent(r);

            setLoading(false);
        });

    }, []);


    function handleFile(f) {

        var reader = new FileReader();

        const rABS = !!reader.readAsBinaryString;

        reader.onload = function (e) {

            const bstr = e.target.result;

            // let csvData = csvJSON(bstr);

            // console.log(csvData);

            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

            const wsname = wb.SheetNames[0];

            const ws = wb.Sheets[wsname];

            const data = XLSX.utils.sheet_to_json(ws, {
                header: schema.tableHeader,
                range: schema.tableRange
            });


            console.log(data);

            processData(data);

        };

        if (rABS) reader.readAsBinaryString(f); else reader.readAsArrayBuffer(f);
    }


    /**
     * 
     * Derive the index of the table from element
     * 
     * @param {]} element 
     */
    function findIndex(element) {

        var key;

        if (typeof schema.tableIndex === 'function') {

            key = schema.tableIndex(element);

        } else {
            // index = schema.tableIndex;

            key = schema.uploadIndex ? element[schema.uploadIndex] : element[schema.tableIndex];

        }

        return key;
    }


    function csvJSON(csv) {
        const lines = csv.split('\n')
        const result = []
        const headers = lines[0].split(',')

        for (let i = 1; i < lines.length; i++) {
            if (!lines[i])
                continue
            const obj = {}
            const currentline = lines[i].split(',')

            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j]
            }
            result.push(obj)
        }


        return result
    }

    /**
     * 
     * Prepare the data according to the configuration
     * 
     * @param {*} data 
     */
    function processData(data) {

        var modified = {};

        var columns = data[0];

        console.log(columns);

        data.forEach((element, index) => {

            if (!schema.includeFirstRow && index < 1) return;

            var index, key;

            // At some cases we have to derive the index from two columns hence we use function
            key = findIndex(element);

            // To prevent errors
            if (typeof key !== 'undefined') {

                // Trim to avoid empty spaces
                key = key.trim();

                // Assign the key to the empty
                modified[key] = {
                    key: key
                };

                // For Many models we import all
                if (schema.importAll) {

                    // For extra params
                    if (schema.extraParams) {

                        modified[key] = {
                            ...schema.extraParams(element, content[key])
                        };
                    }

                    modified[key] = {

                        // Whatever is mentioned in the extra Params
                        ...modified[key],

                        // Override with the existing element 
                        // to make sure we preserve any earlier changes 
                        ...content[key],

                        // This is the new element
                        ...element,

                    }


                } else {

                    schema.columns.forEach((column) => {

                        if (typeof element[column.from] !== 'undefined') {

                            if (typeof element[column.from] === 'number') {

                                modified[key][column.field] = element[column.from];

                            } else {

                                modified[key][column.field] = element[column.from].trim()

                            }

                        } else {

                            modified[key][column.field] = column.default || 'NA'

                        }
                    })

                }
            }
        });

        // We have to compare with old data 
        const previousKeys = records[model].map((entry) => entry[schema.rowIndex]);

        const latestKeys = Object.keys(modified);

        latestKeys.forEach((key) => {
            if (previousKeys.indexOf(key) !== -1) {

                modified[key].duplicate = true;
            }
        });

        setNewparts(modified);

        console.log(modified);
    }

    function approveUpload() {

        let b = {};

        let duplicate = 0;

        Object.keys(newparts).forEach((no) => {

            if (newparts[no].duplicate) {

                duplicate++;

            }

            b[no] = {


                // Here we need to specify any by default fields that we need to add
                ...newparts[no],

                // ...records[model][no]

            };

        });

        let total = Object.keys(b).length;

        let count = total - duplicate;

        FirebaseUtils.writeListing(city, b, model).then((result) => {

            message.success(`${total} ${model} updated. ${count} new ${model}`);

            setNewparts({});

            history.goBack();

        });
    }


    const uploadProps = {
        onRemove: file => {

            var index = files.indexOf(file);

            var newFileList = files.slice();

            newFileList.splice(index, 1);

            setFiles({ ...newFileList });

        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);

                handleFile(info.file);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload: file => {

            setFiles([...files, file]);

            return false;
        },
        files,
    };


    const SheetJSFT = [
        "xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
    ].map(function (x) { return "." + x; }).join(",");


    return (
        <section className="generic-list">

            {/* Table Header */}
            <div className="table-header">

                <div className="table-title">
                    <Title level={4}>
                        {model} Upload
                    </Title>
                </div>

                <div className="table-actions">

                    <div className="button-container">

                        {/* <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button> */}

                    </div>
                </div>
            </div>

            {/* Table Header Ends */}

            {
                loading ? (
                    <PlaceHolder type="listing" />
                ) : <>

                        <div className="card">



                            <Upload previewFile={null} accept={SheetJSFT} {...uploadProps}>
                                <Button size={'small'} icon={<UploadOutlined />}>Select File</Button>
                            </Upload>


                            {
                                Object.keys(newparts).length ?
                                    <div className="file-review">
                                        <div className='action-header'>
                                            <h4>
                                                {Object.keys(newparts).length} new {model}
                                            </h4>

                                            <p>
                                                {Object.keys(newparts).filter((entry) => newparts[entry]['duplicate']).length} are duplicate.
                                            </p>


                                            <Button onClick={approveUpload} key="3">Approve</Button>,

                                            <Button key="2" onClick={() => {

                                                setNewparts({});

                                                setFiles([]);

                                            }}>
                                                Decline
                                            </Button>

                                        </div>

                                    </div>
                                    :
                                    null
                            }


                        </div>

                    </>
            }

        </section>
    );
}

export default withRouter(GenericUpload);
