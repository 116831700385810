
import React, { useState, useEffect, useContext } from "react";

import { GlobalContext } from './../../../../Store';

import { Row, Button, Alert, Typography, Skeleton, Switch, Table, Space } from "antd";

import { Steps, ProcessTransactions } from './../../../../models';

import { OrderedListOutlined, PicCenterOutlined } from '@ant-design/icons';

import './task-list.scss';


const { Title } = Typography;

/**
 * Task List from Step
 * 
 * @param {*} param0 
 */
export default function TaskList({ match }) {

    const [step, setStep] = useState({})

    const { params } = match;

    const { user, CustomModels } = useContext(GlobalContext);

    const [loader, setLoader] = useState(true)

    useEffect(() => {

        loadStep();

    }, [])

    function loadStep() {

        setLoader(true);

        Steps.getRecord(params.id).then((result) => {

            setStep(result);

            setLoader(false);

        })
    }

    return (
        <section className="finance task-list">

            <Title level={3}>
                {step.name}
            </Title>

            <p>
                {step.description}
            </p>

            {loader ? <Skeleton /> : <Tasks step={step} />}

        </section>
    );
}


const Tasks = ({ url, step }) => {

    const { user, CustomModels } = useContext(GlobalContext);

    let cardView = JSON.parse(localStorage.getItem('card-view'))

    const [view, setView] = useState(cardView);

    const [records, setRecords] = useState([]);

    const [loader, setLoader] = useState(false);

    // let models = require('./../../../../models/index');

    let model = CustomModels[step.model_identifier];

    let columns = model.getDefaultColumns(step);

    useEffect(() => {

        loadData(step);

    }, [])


    /**
     * 
     * 
     * @param {*} step 
     */
    function loadData(step) {

        console.log(step);

        setLoader(true);

        var queries = [{
            field: 'step_id',
            value: step.id
        }];

        ProcessTransactions.get(queries).then((result) => {

            // Load model data
            let promises = result.process_transactions.map(loadModelData);

            Promise.all(promises).then((response) => {

                console.log(response);

                setRecords([...response]);

                setLoader(false);

            })
        })
    }

    /**
     * Load the model data
     * 
     * @param {*} param0 
     * @returns 
     */
    function loadModelData(process_transaction) {

        let model = CustomModels[process_transaction.model_identifier];

        return model.getRecord(process_transaction.record_id).then((result) => {

            return {
                ...process_transaction,
                [model.modelName]: result
            }
        })
    }

    function changeView(result) {
        setView(result);

        localStorage.setItem('card-view', result);
    }

    return (<>
        <div className="list-header">

            <p className="page-desc">
                {loader ? 'Loading data' : `Found ${(records).length} results`}
            </p>

            <Space className="list-actions">

                <Switch
                    size={'small'}
                    defaultChecked
                    onChange={changeView}
                    checked={view}
                    checkedChildren={<OrderedListOutlined />}
                    unCheckedChildren={<PicCenterOutlined />}
                />

                <Button onClick={() => { loadData(step) }} type="secondary" size={'small'}>Refresh</Button>
            </Space>

        </div>

        <Row className="report-listing-wrapper">

            {step.alert ? <Alert message={step.alert} type="info" showIcon /> : null}

            {
                loader
                    ?
                    (
                        <Skeleton type="listing" />
                    )
                    :
                    (
                        <>
                            {!view ? (
                                <Table
                                    className="table-exportable"
                                    loading={loader}
                                    size="small"
                                    scroll={{ x: true, y: 750 }}
                                    rowKey={(record) => record.id}
                                    dataSource={records}
                                    columns={columns}
                                    pagination={false}
                                ></Table>
                            ) : (
                                <CardList step={step} model={model} data={records} />
                            )}
                        </>
                    )
            }
        </Row>
    </>
    )
}

/**
 * List of Card : #Note : Should be a generic component
 * 
 * @param {*} param0 
 * @returns 
 */
function CardList({ model, data, step, ...props }) {
    return data.map((record, index) => {
        return <model.TaskCard redirect_to={`task/${step.id}`} index={index} key={index} record={record} {...record} {...props} />;
    });
}

