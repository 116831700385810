import React, { useEffect, useState, useContext } from 'react';

import { Form, Input, Button, Select, Typography } from 'antd';

import { GlobalContext } from '../../../../Store';

import { Pages, Models, Roles } from '../../../../models';

import { useHistory, useParams } from 'react-router-dom';

const { Title } = Typography;

/**
 *
 *
 * @param {*} param0
 * @returns
 */
export default function LookupValuesAdd({ model, formContent, callback, ...props }) {

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    console.log(props);

    const [roles, setRoles] = useState([]);

    const { user } = useContext(GlobalContext);

    const id = useParams().id;

    useEffect(() => {
        getRoles();
    }, []);

    /**
     * Load the Staffs
     */
    const getRoles = () => {
        Roles.get([]).then((result) => {
            console.log(result);

            setRoles(result.roles);
        });
    };

    const onSubmit = (values) => {
        console.log(values);

        values.lookup_type_id = id;

        setLoading(true);

        if (formContent && formContent.id) {

            return model.update(formContent.id, values, user).then(() => {
                callback();

                setLoading(false);

            });

        } else {

            return model.add(values, user).then(() => {
                callback();

                setLoading(false);

            });
        }


    };

    return (
        <section className="collection-add">

            <Title level={4}>Add Lookup Values</Title>

            <Form initialValues={formContent} form={form} layout="vertical" onFinish={onSubmit}>

                {/* Name */}
                <Form.Item label="Name" name="name" required>

                    <Input />

                </Form.Item>
                {/* Name Ends */}

                {/* Description */}
                <Form.Item label="Description" name="description" required>

                    <Input />

                </Form.Item>
                {/* Description Ends */}


                {/* Description */}
                {/* <Form.Item label="Identifier" name="identifier" required>

                    <Input />

                </Form.Item> */}
                {/* Description Ends */}

                {/* Description */}
                <Form.Item label="Value" name="value" required>

                    <Input />

                </Form.Item>
                {/* Description Ends */}


                <Form.Item>
                    <Button loading={loading} htmlType={'submit'} type="primary">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </section>
    );
}
