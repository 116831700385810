/**
 * Generic List accepts an array of fields to update any resource
 */

import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Typography, Button, Table, Modal, Space, Switch, Popconfirm, message, Skeleton } from 'antd';

import { withRouter } from 'react-router-dom';

import './generic-list.scss';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined, DeleteOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

import TableSettings from '../table-settings/table-settings';

import { PortletTable } from '../../../components';

import { ModelColumns, UserPreferences } from '../../../../core/models';

import { Location } from '../../../utils';

import CustomActions from '../../../models/actions/components/custom-actions/custom-actions';

import { MenuGlobalContext } from './../../../components/menu-context/menu-context';

import RangePicker from '././../../../elements/basic/rangepicker/rangepicker';

import DateUtils from './../../../utils/date.utils';

import moment from 'moment';

const { Title } = Typography;

/**
 * Generic List
 *
 * @param {*} param0
 */
function GenericList({
    menu = {},
    match,
    model = {
        columns: [],
        actions: [],
        queries: [],
        filterDate: () => { },
    },
    actions = [],
    queries = model.queries,
    disableAdd = model.disableAdd,
    disableEdit = model.disableEdit,
    disableDelete = model.disableDelete,
    disableSelect = model.disableSelect,
    disableAddModal = model.disableAddModal,
    additional_queries = [],

    config = {
        // limit: 20
    }
    // columns = schema.columns || [],
    // isDirect = schema.isDirect,
    // disableUpload = schema.disableUpload,
    // disableAdd = schema.disableAdd,
    // disableSelect = schema.disableSelect,
    // queries = schema.queries || [],
    // actions = schema.actions || [],
}) {


    let format = 'YYYY-MM-DD hh:mm';

    var params = Location.search();

    // Setting starttime and endtime within a week from today
    var startTime = DateUtils.getStartOfDay();

    var endTime = DateUtils.getEndOfDay();

    initializeRange();

    /**
     * Function to intialize the variables
     */
    function initializeRange() {

        if (params.start_time) {

            // startTime = moment(params.start_time,'')
            startTime = DateUtils.getMomentInFormat(params.start_time, format)
        }

        if (params.end_time) {

            endTime = DateUtils.getMomentInFormat(params.end_time, format)
        }
    }

    const [range, setRange] = useState([startTime, endTime]);

    const [today, setToday] = useState(moment());

    const [records, setRecords] = useState({ [model.name]: [] });

    const [single, setSingle] = useState({});

    const [columns, setColumns] = useState([]);

    const [loading, setLoading] = useState(true);

    let cardView = JSON.parse(localStorage.getItem('card-view'))

    const [view, setView] = useState(cardView);

    const [selected, setSelected] = useState([]);

    const [visible, setVisible] = useState(false);

    const [layout, setLayout] = useState({});

    // Index for the columns : #Note This should be moved to a generic utils to make this complete leaner
    let columnIndex = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => {

                console.log(options.page, options.pageSize, index);

                return (options.page - 1) * options.pageSize + index + 1;
            },
        },
    ]

    // Actions for the generic list
    let columnActions = [

        {
            title: '',
            key: '',
            render: (record, column) => {
                return (
                    <Space size="middle">

                        {/* Edit */}
                        {disableAddModal() || !model.ModalAddComponent ? null : (
                            <Button type="dashed" onClick={() => {

                                setSingle({ ...record });

                                setVisible(true);
                            }}>
                                Edit
                            </Button>
                        )}
                        {/* Edit Ends */}

                        {/* Edit */}
                        {disableDelete() ? null : (
                            <Popconfirm
                                title="Are you sure you want to delete this record ? "
                                onConfirm={() => deleteRecord(record)}
                                onCancel={() => { }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button size="small">
                                    <DeleteOutlined />
                                </Button>
                            </Popconfirm>
                        )}
                        {/* Edit Ends */}


                    </Space>
                );
            },
        },



        {
            title: '',
            key: 'action',
            render: (text, record) => {

                // #Note Redirect link is a temporary fix for menus to work
                // Until models are more strong 

                let basePath = '/admin'

                // let basePath = ''

                let redirectLink = `${basePath}/${model.name}/:id`;

                if (menu.redirect_link) {
                    redirectLink = menu.redirect_link
                }

                redirectLink = redirectLink.replace(':id', text.id)

                return (
                    <Space size="middle">
                        {!model.hideView && !actions.length ? <Link to={redirectLink}>View</Link> : null}

                        {actions.map((action) => (
                            <Link to={action.url(record)}>{action.caption}</Link>
                        ))}
                    </Space>
                );
            },
        },
    ]

    const cols = [
        ...columnIndex
        ,
        ...model.columns.map((entry) => {
            if (entry.sort) {
                return {
                    render: (record) => {
                        if (entry.render) {
                            return entry.render(record);
                        } else {
                            return entry.field.split('.').reduce((acc, part) => acc && acc[part], record);
                        }
                    },
                    title: entry.caption,
                    key: entry.field,
                    sorter: (a, b) => entry.sort(a, b),
                    sortDirections: ['ascend', 'descend', 'ascend'],
                };
            } else {
                return {
                    render: (record) => {
                        if (entry.render) {
                            return entry.render(record);
                            // return entry.field.split('.').reduce((acc, part) => acc && acc[part], record);
                        } else {
                            return entry.field.split('.').reduce((acc, part) => acc && acc[part], record);
                        }
                        // return record[entry.field]
                    },
                    title: entry.caption,
                    key: entry.field,
                };
            }
        }),
        ...columnActions,
    ];

    const [options, setOptions] = useState({
        page: 1,
        current: 1,
        pageSize: 20,
    });

    useEffect(() => {

        loadListing();

    }, []);



    function loadListing() {

        if (menu && menu.model && menu.model.id) {
            // loadConfiguration(menu.model.id);

            loadConfiguration(menu.id).then(() => {

                loadData(null, queries);

            });

        } else {
            setColumns(cols);

            loadData(null, queries);

        }
    }

    /**
     * Load the configuration
     */
    function loadConfiguration(id) {

        var queries = [{
            field: 'menu_id',
            value: id
        }]

        return UserPreferences.get(queries).then((result) => {

            // console.log(result);
            if (result.user_preferences.length) {

                let cols = result.user_preferences[0].selected;

                setLayout(result.user_preferences[0]);

                let mapped = cols.map((record) => {

                    let columnConfig = {
                        dataIndex: record.field,
                        title: record.caption,
                        key: record.field
                    };

                    if (columnConfig.dataIndex.split('.').length > 1) {

                        columnConfig = {
                            ...columnConfig,
                            render: (col, entry) => {

                                return record.field.split('.').reduce((acc, part) => acc && acc[part], entry);

                            }
                        }

                    } else {

                    }

                    return columnConfig;
                })

                setColumns(

                    [
                        ...columnIndex
                        ,

                        ...mapped,
                        ...columnActions,

                    ]
                )

            } else {

                let mapped = model.columns.map((record) => {

                    return {
                        dataIndex: record.field,
                        title: record.caption,
                        key: record.field
                    }
                })



                setColumns(

                    [
                        ...columnIndex
                        ,
                        ...mapped,

                        ...columnActions,

                    ]
                )


            }
        })
    }

    function changeView(result) {
        setView(result);

        localStorage.setItem('card-view', result);
    }

    /**
     *
     */
    const getRecords = ({ queries, startAt }) => {

        let finalConfig = {
            ...config,
            startAt
        }

        var queries = [...additional_queries];

        // Append the url params to the Get Call
        let urlParams = Location.search();

        // Find Final Queries
        // let finalQueries = model.getQueries(queries, urlParams);

        // queries.push()

        // if (model.filterDate()) {
        //     queries.push({
        //         field: 'created_date',
        //         value: moment(today).startOf('day').valueOf(),
        //     });
        // }

        return model.get(queries, finalConfig).then((result) => {

            console.log(result);

            setRecords(result);

            setLoading(false);
        });
    };

    const loadData = (today, queries) => {
        // console.log('queries', queries);

        setLoading(true);

        setToday(today);

        setSelected([]);

        getRecords({ today, queries });
    };


    function updateTime(dt) {
        // setRange(dt);

        let start_time = DateUtils.getFormattedTimeDate(dt[0])

        let end_time = DateUtils.getFormattedTimeDate(dt[1]);

        setRange(dt);

        Location.search({

            start_time,

            end_time,
        });

        loadData(dt[0], dt[1], selectedbranch); //Patiant list get function
    }

    /**
     * Function loads the next set of records
     */
    const nextPage = () => {

        let startAt = records[model.name][records[model.name].length - 1].id

        Location.search({
            startAt: startAt
        })

        getRecords({
            queries,
            startAt
        })

    }

    function refresh() {
        loadData(today, queries);
    }

    /**
     * Delete multiple selected records
     */
    function deleteSelected() {

        setLoading(true);

        Promise.all(
            selected.map((entry) => model.delete(entry.id, { hideAlert: true }))
        ).then(() => {

            message.success('Selected records has been deleted');

            setLoading(false);

            setSelected([]);

            loadData();
        });
    }

    /**
     * Delete the record
     */
    function deleteRecord(record) {

        return model.delete(record.id).then(() => {

            loadData();

        })
    }

    /**
     * Close the modal
     */
    function closeModal(refresh) {

        setSingle({});

        setVisible(false);

        if (refresh) {
            loadData();
        }
    }



    // rowSelection object indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);

            setSelected(selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };


    // Additional Props that needs to be added to the component
    let props = {
    }

    if (!disableSelect) {
        props.rowSelection = rowSelection
    }


    return (
        <section className="generic-list">
            {/* Table Header */}
            <div className="table-header">
                <div className="table-title">
                    <Title level={4}>{menu.name || model.name}</Title>


                    <RangePicker
                        allowClear={false}
                        inputReadOnly
                        format={'DD/MM/YYYY'}
                        value={range}
                        onChange={(range) => {
                            console.log(range);
                            updateTime(range);
                        }}
                    />

                    {/* <p>{loading ? 'Loading records' : `${records[model.name].length} records`}</p> */}
                </div>

                {/* Table Filters */}
                <div className="table-filters">
                    <Space direction="vertical" size={12}>
                        {/* <RangePicker onChange={updateFilter} /> */}

                        {/* 
                        <RangePicker
                            allowClear={false}
                            inputReadOnly
                            format={'DD/MM/YYYY'}
                            value={range}
                            onChange={(range) => {
                                console.log(range);
                                updateTime(range);
                            }}
                        /> */}

                    </Space>
                </div>
                {/* Table Filters Ends */}

                <div className="table-actions">
                    <div className="button-container">

                        {/* Selected Actions */}
                        {selected.length ? (
                            <>
                                {selected.length} selected .
                                <Popconfirm
                                    title="Are you sure you want to delete the selected rows ? "
                                    onConfirm={() => deleteSelected()}
                                    onCancel={() => { }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button size="small">
                                        <DeleteOutlined />
                                    </Button>
                                </Popconfirm>

                                {/* Custom Actions */}
                                {
                                    actions.map((action) => {
                                        return (
                                            <Popconfirm
                                                title="Are you sure you want to perform the action on selected rows ? "
                                                onConfirm={() => action.onClick(selected, refresh)}
                                                onCancel={() => { }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button size="small">{action.caption}</Button>
                                            </Popconfirm>
                                        );
                                    })
                                }
                                {/* Custom Actions */}

                            </>
                        ) : null}

                        <Space size="small">
                            {/* {model.filterDate() ? (
                                <DatePicker defaultValue={today} format={'DD/MM/YYYY'} allowClear={false} onChange={loadData} />
                            ) : null} */}


                            <Button size="small">
                                <LeftOutlined />
                            </Button>
                            <Button size="small" onClick={nextPage}>
                                <RightOutlined />
                            </Button>


                            <Switch
                                size={'small'}
                                defaultChecked
                                onChange={changeView}
                                checked={view}
                                checkedChildren={<OrderedListOutlined />}
                                unCheckedChildren={<PicCenterOutlined />}
                            />

                            <Button onClick={refresh} type="secondary" size={'small'}>
                                <ReloadOutlined />
                            </Button>


                            {menu && menu.id && <CustomActions menu={menu} />}


                            {/* <Button onClick={refresh} type="secondary" size={'small'}>
                                <TableOutlined />
                            </Button> */}

                            {/*  */}
                            {!loading ? <TableSettings refresh={loadListing} layout={layout} menu={menu} model={model} onUpdate={() => { }} /> : null}


                            {/* <Button onClick={exportTableLocal} type="secondary" size={'small'}>
                                Download
                            </Button> */}

                            {/*table data export to csc component*/}
                            {/* {records && <ExportReactCSV csvData={expRecord} />} */}

                            {/* <Button disabled={options.page <= 1} size="small" onClick={() => { }}>
                                <CaretLeftOutlined />
                            </Button>

                            <Button size="small" onClick={() => { }}>

                                <CaretRightOutlined />

                            </Button> */}

                            {/* {
                                disableUpload
                                    ?
                                    null
                                    :
                                    <Button type="dashed">
                                        <Link to={`/${city}/${model}/upload/file`}>
                                            Upload
                                        </Link>
                                    </Button>
                            } */}

                            {/* Add Redirection */}
                            {/* {disableAdd ? null : (
                                <Button type="dashed" size={'small'}>
                                    <Link to={`/${model.name}/create`}>Add</Link>
                                </Button>
                            )} */}
                            {/* Add Redirection Ends */}

                            {disableAddModal() || !model.ModalAddComponent ? null : (
                                <Button size={'small'} type="dashed" onClick={() => setVisible(true)}>
                                    Add
                                </Button>
                            )}
                        </Space>
                    </div>
                </div>
            </div>

            {/* Table Header Ends */}

            {loading ? (
                <Skeleton type="listing" />
            ) : (
                <>
                    <>
                        {!view ? (

                            <PortletTable records={records[model.name]} columns={columns} />
                            // <Table
                            //     // ref={(ref) => { tableRef = ref }}
                            //     className="table-exportable"
                            //     loading={loading}
                            //     size="small"
                            //     // rowSelection={rowSelection}
                            //     scroll={{ x: true, y: 750 }}

                            //     // scroll={{ x: true, y: 750 }}
                            //     rowKey={(record) => record.id}
                            //     // rowKey={record => record[model + 'Id']}
                            //     dataSource={records[model.name]}
                            //     columns={columns}
                            //     // onChange={handleTableChange}
                            //     pagination={false}

                            //     {...props}

                            // ></Table>
                        ) : (
                            <CardList model={model} data={records[model.name]} />
                        )}
                    </>
                </>
            )}

            {/* Add Modal */}
            <Modal width={'80%'} destroyOnClose confirmLoading={loading} title={`Add ${model.name}`} visible={visible} onCancel={() => { closeModal() }} footer={null}>
                <model.ModalAddComponent
                    match={match}
                    menu={menu}
                    model={model}
                    additional_queries={additional_queries}
                    formContent={single}

                    callback={() => {
                        closeModal(true);
                    }}
                />
                {/* <FormCreator formContent={{ [model]: {} }} modelIndex="requestId" model={model} onSubmit={createRecord} config={step} /> */}
            </Modal>

            {/* Add Modal Ends */}
        </section>
    );
}

export default withRouter(GenericList);

function CardList({ model, data, ...props }) {
    return data.map((record, index) => {
        return <model.Card index={index} key={index} record={record} {...record} {...props} />;
    });
}
