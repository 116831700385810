import Base from './../base';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

import ColumnsAdd from './components/columns-add/columns-add';

import { LookupValues } from '..';

class Columns extends Base {

    constructor() {
        super();

        this.fields = [
            {
                field: 'caption',
                caption: 'Caption',
            },
            {
                field: 'field',
                caption: 'Field',
            },
            {
                field: 'description',
                caption: 'Description',
            },
        ];

        // this.columns = ;
    }

    get getEndpoint() {
        return 'columns';
    }

    get path() {
        return `columns`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `column`;
    }

    disableAddModal() {
        return false;
    }

    disableAdd() {
        return false;
    }


    get columns() {
        return [
            {
                caption: 'Caption',
                field: 'caption',
                key: 'caption',
            },

            {
                caption: 'Field',
                field: 'field',
                key: 'field',
            },
            {
                caption: 'Description',
                field: 'description',
                key: 'description',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    /**
     * Get the columns
     * 
     * @param {*} queries 
     * @returns 
     */
    getColumns = (queries) => {

        return this.get(queries).then((result) => {

            return Promise.all(result.columns.map((column) => this.getColumnDetail(column)));

        })
    }

    /**
     * Get the column detail by appending the 
     * lookvalue result to type
     * 
     * @param {*} column 
     * @returns 
     */
    getColumnDetail = (column) => {

        return LookupValues.getRecord(column.column_type_id).then((result) => {

            return {
                ...column,
                type: result.value
            }
        })
    }

    /**
     * Get the data from the table
     */
    // get = (queries) => {
    //     return this.getMethod(queries).then((result) => {
    //         return Promise.all(result.menu_roles.map(this.loadRoles)).then((result) => {
    //             return { menu_roles: result };
    //         });
    //     });
    // };

    // Attach the related staff with the object
    // loadRoles = (item) => {
    //     return Roles.getRecord(item.role_id).then((result) => {
    //         return {
    //             ...item,
    //             role: result,
    //         };
    //     });
    // };

    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    Card = (record) => {
        return (
            <Link className="booking-card" to={`/collections/${record['id']}`}>
                <div className="card">
                    <h2 className="">{record.caption}</h2>

                    <h3 className="title">{record.description}</h3>

                    <p className="address">
                        Collected on {record.created_at}
                        <Tag color="magenta">{record['Consultant Name']}</Tag>
                    </p>
                </div>
            </Link>
        );
    };

    ModalAddComponent = (props) => <ColumnsAdd model={this} {...props} />;

}

export default Columns;
