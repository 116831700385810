


import { message } from 'antd';


export default class Notification {


    /**
     * Trigger a success message
     * 
     * @param {*} message 
     */
    static success = (text) => {

        message.success(text)

    }

    /**
     * Trigger a notification of type with message
     * 
     * @param {*} param0 
     */
    static trigger = ({ type, text }) => {

        message[type](text);
    }


}