
/**
 * Generic Detail accepts an array of fields to update any resource
 */

import React, { useState, useEffect, useContext } from "react"

import { Link } from 'react-router-dom'

import { Typography, Button, Descriptions, Input, Switch, Space, Tag } from 'antd';

import { withRouter } from 'react-router-dom'

import './generic-detail.scss'

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';


import useDeviceDetect from "../../../hooks/device-detect";

import PlaceHolder from "../../../components/ui_elements/PlaceHolder";

import { GlobalContext } from "../../../Store";


const { Title } = Typography;

/**
 * Generic List 
 * 
 * @param {*} param0 
 */
function GenericDetail({
    match,
    model,
    columns: cols,
    schema = { custom_actions: [] },
    disableEdit = schema.disableEdit,
    menu

}) {

    const [record, setRecords] = useState([]);

    const { isMobile } = useDeviceDetect();

    const { user } = useContext(GlobalContext);

    // const city = user.locations[0];

    const { params } = match;

    const { city } = params;

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        loadData();

        // console.log('hello');

    }, []);

    const loadData = () => {

        setLoading(true);

        getRecords(schema)
    }

    const getRecords = (schema) => {

        // Get the model by queries
        return model.getRecord(match.params.id, model).then((result) => {

            setRecords(result);

            console.log(result);

            setLoading(false);

        })
    }


    function refresh() {

        loadData();
    }

    return (
        <section className="generic-list">

            {/* Table Header */}
            <div className="table-header">

                <div className="table-title">
                    <Title level={4}>
                        {model.name}
                    </Title>
                </div>

                <div className="table-actions">

                    <div className="button-container">

                        {menu && menu.id && <CustomActions menu={menu} />}

                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>

                        {
                            disableEdit
                                ?
                                null
                                :
                                <Space size="middle">
                                    <Button type="dashed">
                                        <Link to={`/${model.name}/${match.params.id}/e`}>
                                            Edit
                                        </Link>
                                    </Button>
                                </Space>
                        }

                    </div>
                </div>
            </div>

            {/* Table Header Ends */}

            {
                loading ? (
                    <PlaceHolder type="listing" />
                ) : <>

                    <div className="card">

                        <Descriptions title="Detail">

                            {
                                Object.keys(record).map((field, key) => {


                                    if (record[field]) {

                                        if (typeof record[field] === 'string' || typeof record[field] === 'number') {

                                            return <Descriptions.Item key={key} label={field}>{record[field]}</Descriptions.Item>

                                        } else if (typeof record[field] === 'object') {

                                            let nested = record[field];

                                            Object.keys(record[field]).map((entry) => {

                                                return <Descriptions.Item key={key} label={entry}>{record[field][entry]}</Descriptions.Item>

                                            })


                                        } else {
                                            console.log(field, record[field]);
                                        }

                                    }

                                })
                            }

                            {/* {cols.map((column) => <Descriptions.Item label={column.caption}>{record[column.field]}</Descriptions.Item>)} */}

                        </Descriptions>

                        {/* 
                            <RecordForm
                                record={record[model]}
                                // index={id}
                                // formContent={{}}
                                // modelIndex='requestId'
                                model={schema.model}
                                step={schema.form}
                                callback={(params, values) => {

                                }}
                            /> */}

                    </div>

                </>
            }

        </section>
    );
}

export default withRouter(GenericDetail);
