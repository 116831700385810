import React, { useEffect } from "react";
import { CSVLink } from "react-csv";
import { Button } from "antd";

export const ExportReactCSV = ({ csvData, fileName }) => {
  return (
    <Button type="secondary" size="small" variant="warning">
      {csvData && (
        <CSVLink data={csvData} filename={"Report.csv"}>
          Download
        </CSVLink>
      )}
    </Button>
  );
};
