/**
 * Image Wrapper
 */


import React, { useState } from "react";


import { Modal, Button } from 'antd';

import ImageWrapper from './../image-wrapper/image-wrapper';

export default function ImagePreview({ type, content, field }) {

    const [visible, setVisible] = useState(false);

    return (<div className="">

        <Button onClick={() => {

            setVisible(true);

        }}>View Preview</Button>


        {/* Add Modal */}
        <Modal width={'80%'} destroyOnClose title={`Preview`} visible={visible} onCancel={() => { setVisible(false) }} footer={null}>

            <ImageWrapper type={type} content={content} />
        </Modal>

    </div>)
}
