
import React, { useEffect, useState } from 'react';

import { Space, Typography, Button } from 'antd';

import { CustomActions } from '../../..';

import './page-container.scss';

const { Title } = Typography;

/**
 * 
 * Page Container
 * 
 * @param {*} param0 
 * @returns 
 */
export default function PageContainer({ title, onRefresh, children, context, menu = {} }) {

    return (<div className='page-container'>

        <div className="page-header">

            <Title level={4}>
                {title}
            </Title>


            <div className="page-actions">

                <Button
                    size={'small'}
                    onClick={onRefresh}
                    type="secondary">
                    Refresh
                </Button>

                <CustomActions menu={menu} record={context} />

            </div>

        </div>

        {/* Entire Children */}
        {children}
        {/* Entire Children Ends */}

    </div>)
}
