/**
 * 
 * 
 * @description Component for Showing the status of this step 
 * @author Ashique
 */


import React, { useState, useEffect, useContext } from 'react';

import { Tag, Button, message } from 'antd';

import './task-status.scss';

import DateUtils from './../../../../utils/date.utils';

import { ConfirmModal } from './../../../../components/'

import { CopyToClipBoard } from './../../../../elements/'

import { StepTransactions } from '../../..';

import { GlobalContext } from './../../../../Store';

/**
 * 
 */
export default function TaskStatus({ color, step, step_transactions }) {

    // let activeStepTransactions = step_transactions.filter((record) => record.cancelled_at === null)[-]
    const { user = { locations: [] } } = useContext(GlobalContext);


    /**
     * Copy to clipboard
     * 
     * @param {*} step 
     */
    async function cancelStepTransaction({ step_transactions, remarks }) {

        var formBody = {
            cancellation_remarks: remarks,
            cancelled_at: new Date()
        }

        await StepTransactions.update(step_transactions.id, formBody)

        message.success("Step Transaction Cancelled", step_transactions.id);
    }

    return (<div className='task-status'>

        <div>


            {
                step_transactions
                    ?
                    <>

                        <small className='timeline'>
                            {DateUtils.displayFirestoreTime(step_transactions.start_time)} -  {step_transactions.completed ? DateUtils.displayFirestoreTime(step_transactions.end_time) : 'Pending'}
                        </small>

                        {/* {
                        step_transactions.completed
                            ?
                            <p>
                                Time Taken : {DateUtils.getDuration(step_transactions.start_time, step_transactions.end_time)}
                            </p>
                            :
                            null
                    } */}


                        {/* Roles the Step is assigned to  */}
                        {
                            step.role_id
                                ?
                                <p>
                                    <Tag color={color}>

                                        <small>{step.current_state}

                                            {/* with {step.role.name} */}

                                        </small>
                                    </Tag>

                                </p>
                                :
                                null
                        }

                        {step.roles ? <>

                            Pending with {step.roles.map((role) => {
                                return role.name
                            })}


                        </> : <></>}



                        {/* {step.current_state === 'ongoing' ? <p>

                        Waiting Since : {DateUtils.getPastTime(step_transactions.start_time,)}

                    </p> : <></>} */}

                        {/* Roles the Step is assigned to Ends */}
                    </>
                    :
                    <>

                        {/* <i className={`pending-tag ${step.current_state}`}>
                        <small>
                            {step.current_state}
                        </small>
                    </i> */}

                        <Tag color={color}>
                            {step.current_state}
                        </Tag>

                    </>
            }
            {/* Summary of Step Activity Ends */}

        </div>


        {/* Actions for Step Transactions */}
        <div className='actions'>

            {
                step_transactions && user.isAdmin
                    ?
                    <>

                        <CopyToClipBoard id={step_transactions.id} record={step_transactions} />

                        <ConfirmModal caption='Cancel' callback={({ remarks }) => { cancelStepTransaction({ step_transactions, remarks }) }} />

                    </>
                    :
                    null
            }

        </div>
        {/* Actions for Step Transactions Ends */}

    </div>)

}