/**
 *
 * Generic Form create accepts an array of fields to update any resource
 */

import React, { useState, useEffect } from 'react';

import { Select } from 'antd';

import { BaseLoader } from '../../..';

const { Option } = Select;

/**
 * Component for selecting head
 *
 * @param {*} param0
 * @returns
 */
function ReferenceSelect({ model, path = '', value, queries = [], onChange, label = "name", mode = "" }) {

    let [options, setOptions] = useState([]);

    useEffect(() => {
        loadOptions()
    }, [])

    /**
     * Load the matching heads
     */
    function loadOptions() {

        if (mode === 'nura-single') {

            model.get(queries).then(({ result }) => {

                setOptions(result);

            })

        } else {

            if (path) {

                let dynamicModel = new BaseLoader({ path })

                dynamicModel.get(queries).then((result) => {

                    setOptions(result[dynamicModel.name]);

                })

            } else {

                model.get(queries).then((result) => {

                    setOptions(result[model.name]);

                })
            }
        }

    }

    let props = {}

    if (mode == 'multiple') {
        props.mode = mode;
    }


    return (
        <div className="head-selector">
            <Select {...props} onChange={onChange} value={value}>
                {options.map((option, key) => (
                    <Option key={key} value={option.id}>
                        {option[label]}
                    </Option>
                ))}
            </Select>
        </div>
    );
}

export default (ReferenceSelect);