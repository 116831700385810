
import React, { useState, useContext } from "react"

import { Form, Button, Input, message, Result } from 'antd';

import { withRouter, Link } from 'react-router-dom'

import './login.scss'

import FirebaseUtils from '../../../core/utils/firebase.utils';

import ApiUtils from "../../utils/api.utils";

import { GlobalContext } from './../../Store';

// var logo = require('./../../assets/images/logo.png');

// import logo from './../../../assets/images/max-life-logo.png';

// import fujidkh from './../../../assets/images/fuji-dkh.png';


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const tailLayout = {
    wrapperCol: { span: 8 },
};


function LoginPhone({ history, appSettings }) {

    // console.log(history);

    const { brandLogo, heroImage, footerLogo, headers } = appSettings;

    const { user, dispatch } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const onFinish = values => {

        setLoading(true);

        if (process.env.REACT_APP_PRIMARY_BACKEND === 'SQL') {

            let formBody = {
                username: values.email,
                password: values.password
            }

            return ApiUtils.post({ url: 'auth/login', formBody, headers }).then(({ user, access_token }) => {

                setLoading(false);

                localStorage.access_token = access_token;

                if (user.id) {

                    let d = user;

                    let userInfo = {
                        id: user.id,
                        locations: [],
                        ...d,
                        ...{
                            loggedCheckDone: true
                        }
                    }

                    dispatch({ type: 'user', payload: userInfo });

                    localStorage.setItem('userInfo', JSON.stringify(userInfo));

                    history.push('/admin/');

                } else {

                    message.error('No accounts found.');

                }


            }).catch(() => {

                setLoading(false);

            })

        } else {


            FirebaseUtils.loginWithEmail(values).then((result) => {

                setLoading(false);

                if (result.id) {

                    if (result.active) {

                        let d = result;

                        let userInfo = {
                            id: result.id,
                            locations: [],
                            ...d,
                            ...{
                                loggedCheckDone: true
                            }
                        }

                        if (result.role && result.role.name === 'super') {

                            // if (result.role && result.role.name === 'admin') {

                            userInfo.isAdmin = true;

                        }


                        dispatch({ type: 'user', payload: userInfo });

                        localStorage.setItem('userInfo', JSON.stringify(userInfo));

                        history.push('/admin/');

                    } else {

                        message.error('Your account is inactive, Please contact support.');

                    }

                } else {

                    message.error('No accounts found.');

                }
            }).catch((result) => {
                console.log(result);

                setLoading(false);

                message.error('Login Failed');
            })
        }

    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };


    return (
        <section className="user-authentication-section">
            <div className="auth-form-wrapper">

                <div className="brand-logo">
                    {/* Your Feedback  */}
                    <img className="logo-welcome" src={brandLogo} alt="Logo" />
                </div>

                <div className="form-title">
                    <h3>Login</h3>

                    <p>Your work assistant for the day !</p>
                </div>

                <div>

                </div>

                {heroImage && <img className="vehicle-image" src={heroImage} alt="Logo" />}


                <Form
                    {...layout}
                    layout='vertical'
                    name="basic"
                    // initialValues={{ email: 'afil.ahammed@gmail.com', password: '123456' }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item> */}

                    <Form.Item {...tailLayout}>
                        <Button loading={loading} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                <p style={{ marginTop: '20px' }}>
                    <>👋</> <small>
                        {process.env.REACT_APP_landing_quote} . <small>{process.env.REACT_APP_package_version}</small>
                    </small>

                </p>

                <div className="sidebar-footer">

                    {/* <Link to="/register">Create Account</Link> */}

                    <img className="footer-logo" src={footerLogo} alt={'user photograph'} />

                </div>


            </div>
        </section>
    );
}

export default withRouter(LoginPhone)