/**
 * Generic Edit Form create accepts an array of fields to update any resource
 */

import React, { useState, useEffect, useContext } from 'react';

import { Typography, Button, Input } from 'antd';

import { withRouter } from 'react-router-dom';

import './generic-edit.scss';

import { ReloadOutlined } from '@ant-design/icons';

import useDeviceDetect from '../../../hooks/device-detect';

import PlaceHolder from '../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../Store';

import FormCreator from './../../../models/forms/components/form-creator/form-creator';

const { Title } = Typography;

/**
 * Generic List
 *
 * @param {*} param0
 */
function GenericEdit({ history, match, model, columns: cols }) {
    const [record, setRecord] = useState([]);

    const { isMobile } = useDeviceDetect();

    const { user } = useContext(GlobalContext);

    const { params } = match;

    const { city } = params;

    const [loading, setLoading] = useState(false);

    const step = {
        fields: cols,
    };

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setLoading(true);

        // Get the model by queries
        return model.getRecord(match.params.id, model).then((result) => {
            setRecord(result);

            setLoading(false);
        });
    };

    /**
     *
     *
     * @param {*} values
     */
    const onSubmit = (values) => {
        return model.update(match.params.id, values, user).then(() => {
            history.goBack();
        });
    };

    function refresh() {
        loadData();
    }

    return (
        <section className="generic-list">
            {/* Table Header */}
            <div className="table-header">
                <div className="table-title">
                    <Title level={4}>{model.name}</Title>

                    <p>Edit the record below</p>
                </div>

                <div className="table-actions">
                    <div className="button-container">
                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>
                    </div>
                </div>
            </div>

            {/* Table Header Ends */}

            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    <div className="card">
                        <FormCreator
                            formContent={record}
                            modelIndex="requestId"
                            model={model}
                            onSubmit={onSubmit}
                            config={step}
                            callback={() => {
                                history.goBack();
                            }}
                        />
                    </div>
                </>
            )}
        </section>
    );
}

export default withRouter(GenericEdit);
