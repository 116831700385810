/**
 * Class would have the user preferences
 * 
 */


import { Base } from './../';

class UserPreference extends Base {

    constructor() {
        super();

        this.fields = [
            {
                field: 'caption',
                caption: 'Caption',
            },
            {
                field: 'field',
                caption: 'Field',
            },
            {
                field: 'description',
                caption: 'Description',
            },
        ];
    }

    get getEndpoint() {
        return 'user_preferences';
    }

    get path() {
        return `user_preferences`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `user_preference`;
    }

    disableAddModal() {
        return false;
    }

    disableAdd() {
        return false;
    }


    get columns() {
        return [
            {
                caption: 'Caption',
                field: 'caption',
                key: 'caption',
            },

            {
                caption: 'Field',
                field: 'field',
                key: 'field',
            },
            {
                caption: 'Description',
                field: 'description',
                key: 'description',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

}

export default UserPreference;
