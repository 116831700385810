/**
 * 
 * The application checks the domain to check with connected database 
 * for loading the config and then bootstrapping the launch page of the application  
 *  
 * The launch is often followed by the different parts of the application as
 * configured from the dashboard 
 */

import React, { useEffect, useContext, useState } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import {
    GlobalHeader,
    Landing,
    LaunchPage
} from '..';


import { Login } from '../../pages'

import {
    GlobalContext,
    GlobalProvider,
} from '../../Store';

import { Location } from '../../utils'

function ClientApplication({ CustomComponents, CustomModels, appSettings, steps = {}, legacyMode = false, ...props }) {

    console.log("Starting Application");

    const { footerLogo } = appSettings;

    // Path/ Subdomain of the application
    // let path = Location.path();

    useEffect(() => {

        // path


        return () => { };
    }, []);

    return (
        <GlobalProvider CustomModels={CustomModels}>
            <Router>
                <Route path="/" component={(props) => {

                    return <Routes steps={steps} {...props} appSettings={appSettings} CustomComponents={CustomComponents} CustomModels={CustomModels} />

                }} />
            </Router>
        </GlobalProvider>
    );
}


/**
 * The routes of the application'
 * 
 * @param {*} param0 
 * @returns 
 */
function Routes({ history, CustomComponents, appSettings, CustomModels, steps }) {

    const [loader, setLoader] = useState(false);

    const { user = {}, twilio } = useContext(GlobalContext);

    const { footerLogo } = appSettings;


    useEffect(() => {
        Location.getHistoryMethod(getRouterProps);
    }, []);

    useEffect(() => {

        // console.log(user);

        if (user && user.loggedCheckDone) {

            // Redirect to login page
            setLoader(false);

            // if (!user.id) {

            //     history.push('/home');
            // } else {

            //     history.push('/admin/');

            // }
        }
    }, [user]);


    const getRouterProps = () => ({ history: history });


    useEffect(() => {
        return () => { };
    }, []);

    return (
        <div className={`app`}>

            {/* 
            There are two parts to the application now , One what the customer sees
            and then what the internal team uses
             */}
            <div className={`content ${twilio ? 'connected' : ''}`}>
                {/* Onboarding welcome card */}
                {!loader && user.loggedCheckDone ? (
                    <Switch>


                        {/* Login Page */}
                        <Route exact path="/home" render={(routeParams) => {

                            return <LaunchPage appSettings={appSettings} {...routeParams} />
                        }} />
                        {/* Login Page Ends */}



                        {/* Login Page */}
                        <Route exact path="/admin/login" render={(routeParams) => {

                            return <Login appSettings={appSettings} {...routeParams} />
                        }} />
                        {/* Login Page Ends */}

                        {/* Register Page */}
                        {/* <Route exact path="/register" render={(routeParams) => {

                                return <Register appSettings={appSettings} {...routeParams} />
                            }} /> */}
                        {/* Register Page Ends */}

                        <Route
                            // exact
                            path=""
                            // component={Landing}
                            render={(routeParams) => <Landing

                                steps={steps}

                                appSettings={appSettings}
                                CustomComponents={CustomComponents}
                                CustomModels={CustomModels}
                                {...routeParams}
                            />}
                        ></Route>
                    </Switch>
                ) : (
                    <>
                        <GlobalHeader appSettings={appSettings}
                        />
                        {/* <PlaceHolder type="listing" /> */}
                    </>
                )}
            </div>




        </div>
    );
}




export default ClientApplication;
