/**
 *
 * Component would load the action linked to the menu and add the functionality
 * 
 * Can support few types of actions 
 * 
 * Dropdown 
 * Header
 * Table Row
 * Footer
 * 
 * 
 */

import React, { useState, useEffect } from 'react';

import { Actions, Scripts } from '../../..';

import { ExecuteScript } from "../../../../utils/script.utils";

import { Select, Button, Menu, Dropdown } from 'antd';

import * as Models from './../../../';

import * as Utils from './../../../../utils/'

import { DownOutlined } from '@ant-design/icons';

let baseModels = require('../../../../../core/models/')


/**
 * Component for selecting head
 *
 * @param {*} param0
 * @returns
 */
function CustomActions({ menu, record = {}, position = "header" }) {

    let models = {
        ...baseModels,
        ...Models
        // ...businessModels
    }

    let [options, setOptions] = useState([]);

    useEffect(() => {
        loadActions();
    }, [])

    /**
     * Load the matching heads
     */
    function loadActions(table) {

        var queries = [{
            field: 'menu_id',
            value: menu.id
        }, {
            field: 'active',
            value: true
        }]

        Actions.get(queries).then(async (result) => {

            var actions = await Promise.all(result.actions.map((action) => loadScript(action)));

            setOptions(actions);

            // console.log(result);

        })
    }


    /**
     * Load the Script statement
     * 
     * @param {*} script_id 
     */
    const loadScript = (action) => {

        return Scripts.getRecord(action.on_click_script_id).then((result) => {

            return {
                ...action,
                on_click: result.value
            }

        })
    }

    async function onClick(option) {

        // On Submit of form , execute the script
        await executeOnSubmitScript(option);

    }

    /**
     * 
     * 
     * @param {*} values 
     * @returns 
     */
    async function executeOnSubmitScript(option) {

        var scripts = [];

        if (option.on_click) {
            scripts.push(option.on_click);
        }

        if (option.on_click) {

            return await ExecuteScript({
                formContent: {}, scripts, context: {
                    ...models,
                    ...Utils
                }, contextName: 'models'
            });

        } else {

            return new Promise(function (resolve, reject) {
                resolve('start of new Promise');
            });
        }
    }



    /**
     * 
     */
    const renderButtons = (actions) => {

        const menu = (
            <Menu>

                {
                    actions.map((option) => {
                        return <Menu.Item>
                            <Button onClick={() => onClick(option)} size={'small'}>{option.caption || option.name}</Button>
                        </Menu.Item>
                    })
                }

            </Menu>
        );

        return (<Dropdown overlay={menu} trigger={['click']}>
            <Button size="small" icon={<DownOutlined />}>


                Actions


            </Button>
        </Dropdown>)

    }

    return (
        <div className="custom-actions">

            {
                position === "header"
                    ?
                    <>

                        {renderButtons(options)}

                    </>
                    :
                    <>
                        {options.map((option) => <Button onClick={() => onClick(option)} size={'small'}>{option.caption || option.name}</Button>)}
                    </>
            }

        </div>
    );
}

export default (CustomActions);