/**
 * Generic Edit Form create accepts an array of fields to update any resource
 */

import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Typography, Skeleton } from 'antd';

import { withRouter } from 'react-router-dom';

import './generic-add.scss';

import { GlobalContext } from '../../../Store';

import { Columns, LookupValues } from '../../../../core/models/';

import FormCreator from './../../../models/forms/components/form-creator/form-creator';

const { Title } = Typography;

/**
 * Generic List
 *
 * @param {*} param0
 */
function GenericAdd({ match, menu, model, additional_queries = [], columns: cols, history, schema, formContent, callback, ...props }) {

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [columns, setColumns] = useState([]);

    useEffect(() => {

        setLoading(true);

        if (menu && menu.model && menu.model.id) {
            loadConfiguration(menu.model.id);
        } else {
            setColumns(model.fields);

            setLoading(false);
        }

    }, []);

    /**
     * Load the configuration
     */
    async function loadConfiguration(id) {

        var queries = [{
            field: 'model_id',
            value: id
        }]

        const { columns } = await Columns.get(queries);

        let mapped = await Promise.all(columns.map((column) => getColumnDetail(column)))

        console.log(mapped);

        setColumns(
            [
                ...mapped,
            ]
        )

        setLoading(false);

        // return Columns.get(queries).then((result) => {

        //     let mapped = result.columns.map((record) => {

        //         return LookupValues.getRecord(record.column_type_id).then((result) => {

        //             return {
        //                 field: record.field,
        //                 caption: record.caption,
        //                 // caption: record.caption,
        //                 key: record.field,
        //                 type: result.value
        //             }
        //         })
        //     });

        //     console.log(mapped);

        //     setColumns(
        //         [
        //             ...mapped,
        //         ]
        //     )

        //     setLoading(false);

        // })
    }

    /**
     * 
     */
    const getColumnDetail = async (column) => {

        return LookupValues.getRecord(column.column_type_id).then((result) => {

            return {
                field: column.field,
                caption: column.caption,
                key: column.field,
                type: result.value,
                ...column
            }
        })
    }

    /**
     *
     *
     * @param {*} values
     */
    const onSubmit = (values) => {

        // values = {
        //     ...values,
        //     additional_queries
        // }
        // 
        additional_queries.forEach((query) => {

            values[query.field] = query.value;
        })

        // If there is a change property change , change it to some other format
        columns.forEach((entry) => {
            if (entry.changeTo) {
                values[entry.field] = entry.changeTo(values[entry.field]);
            }
        });


        if (formContent && formContent.id) {

            return model.update(formContent.id, values, user).then(() => {
                // history.goBack();

                callback();
            });

        } else {

            return model.add(values, user).then((result) => {
                // history.goBack();

                callback();

                console.log(result);
            });
        }
    };

    // Override model fields by columns
    // model.fields = columns;

    return (
        <section className="generic-list">
            {/* Table Header */}
            <div className="table-header">
                <div className="table-title">
                    {/* <Title level={4}>{model.name}</Title> */}

                    <p>Add new record below</p>
                </div>

                <div className="table-actions">
                    <div className="button-container">
                        {/* <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button> */}
                    </div>
                </div>
            </div>

            {/* Table Header Ends */}
            {loading ? (
                <Skeleton type="listing" />
            ) : (
                <>
                    <div className="card">

                        <FormCreator
                            formContent={{
                                // [model.name]: {
                                ...formContent
                                // }
                            }}
                            fields={columns}
                            modelIndex="requestId"
                            model={model}
                            onSubmit={onSubmit}
                            callback={() => {
                                history.goBack();
                            }}
                        />
                    </div>
                </>
            )}
        </section>
    );
}

export default withRouter(GenericAdd);
