import React, { useContext } from "react";

import { GlobalContext } from './../../Store';

// import TaskOverview from './../../../core/models/process/components/task-overview/task-overview';

import TaskOverviewLegacy from '../../models/process/components/task-overview-legacy/task-overview-legacy'

import NoticeBoard from '../../../core/components/notice-board/notice-board';

export default function HomePage({ config, steps }) {

    const { user = {} } = useContext(GlobalContext);

    if (user.role) {


        // var path = `../../business/${role}/${role}-config`

        // var path = './../../business/admin/admin-config';

        // console.log(path);

        // config = require('./../../business/admin/admin-config')


        // config = require(path);

        // config = require(`./../../business/${user.role}/${user.role}-config.js`);
        // console.log(config);
    }

    return (

        <span className="homepage">

            <NoticeBoard />

            <br />

            {steps.length ? <TaskOverviewLegacy steps={steps} /> : null}

            {/* <PdfViewer url={'https://00f74ba44b71715c976fab384bb78dd5cf647e30f9-apidata.googleusercontent.com/download/storage/v1/b/maxlife-c7be8.appspot.com/o/ashique-document.pdf?jk=AFshE3UotJLtsvKbRzL9tz1uM2WA6sDfxIrFVovQbwH9jAfurTmloMiaITr6xDo0inBTZFJTXSZwL3DOICzoiYcnEYUyhRDw4YYjPIh9Z-h9bodfwUDI3gW35b9oh4u4665vi04hB14EWUELQhqNWzfRTyKFZMVALhp_LcUqAkQL34MJCqv6mswUNE_ics6fUIDOKzVCjBgFl2hzqR9uuYDEI4xgKcv0N2bFt4oWectXVYvfbaOBaW1qnFdRsApIePn8BypX7cZosYs_2ivhBCKddXrxAfLZGmFk_2Oo694O8r_PH5hi87V_zFTyF5SYMOlKYNP-jyrR_r-o15VCF_QLnQtBwB7eDE07SPb9EQs5JpkD-wpVc1svDvu2I_XwG4zB5ei8Dh_WMkaOTY58ozKa3aY9cLxEnISw4sb849kzIEAP2hNSWoUTgYswh3NOlV7YIbBZY5W859VBK5si2POVInDsR1P5AyGNQ9HuMSr09X4m7Cz0bSmtnVBCq_1usJrIEBYk1ZzbHafenKp9JOMXrZbIVTZYivNBvRMjY3iNqjyhmcgOOM4Ab1Q1docBYw5Ja35zDFe8tOnTNqmyNPlYrVefhYvwVXpH7xBmq7gFpFPbaJ_aZjQSsOtdRprXbHFdHVoXkxtWHUG0m6Nmnm-4yWXRJFoDFqxOPD5yGMwCyzyI3QVvQam9ZcXkKFRxh_4OnK1jURgYbz0HkJUiloxtNEzQE4Cyzfyn37xOwgbN1kg8E1mlEj74jcfD3578S9nybLTpKvX3hBoOg3CCn6_7owndAg8CznOnzItEpXBVYf9aJIYk8FmnewDCwAY0VioeP5e8-3amuBX4LtiLlR97S4dNglsarWFjwuBDor8RSABRs26wOQjtDFhtRb_7j-8F2mNl3Q3jLZFbCG_xOwzDlvqTM_CUM1Be03Ogy4A44sR92_AERZkG6BuH-X7YmETtLTCcvuGHRQYxLsLL0e4PLR9zOMOQ&isca=1'} /> */}

        </span>
    );
}
