/**
 * 
 * @author Ashique Mohammed
 * @description Generic Form create accepts an array of fields to update any resource
 * @version
 * 
 */

import React, { useState } from "react"

import moment from 'moment';

import { Button, Form, Input, Radio, InputNumber, DatePicker, TimePicker, Checkbox, Select, Row, Col } from 'antd';

import FileUpload from './../../../../components/file-upload/file-upload';

import Camera from './../../../../components/camera/camera';
import { DateUtils } from "../../../../utils";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const { TextArea } = Input;

const { Option } = Select;

import './form-creator.scss'

/**
 * Component accepts fields to render the necessary components and returns the values on submission
 * 
 * @param {*} param0 
 * @returns 
 */
function FormCreator({ model, formContent = {}, onSubmit, fields = [], callback }) {

    const [form] = Form.useForm();

    // Variable used for loading state
    const [loading, setLoading] = useState(false);

    // Variable stores the form body values
    const [content, setContent] = useState(() => {

        // Body for the files
        let body = formContent || { [model.name]: {} };

        fields.forEach((entry) => {

            if (entry.transform) {

                body[entry.field] = entry.transform(body[entry.field]);
            }

            // If its a date
            if (['date'].indexOf(entry.type) !== -1 && formContent[entry.field]) {

                body[entry.field] = DateUtils.getMomentObject(formContent[entry.field]);
            }

        })

        return body;

    });

    /**
     * Function handles trigger of onupload
     * 
     * @param {*} element 
     * @param {*} attachments 
     */
    function onUpload(element, attachments) {
        form.setFieldsValue({ [element.field]: attachments });
    }

    /**
     * Custom Listener for onchange of input 
     * 
     * @param {*} element 
     * @param {*} attachments 
     */
    function onChange(element, value) {
        form.setFieldsValue({ [element.field]: value });
    }

    return (
        <section className="form-creator">

            <Form
                form={form}
                {...layout}
                name="new-record"
                onFinish={(values) => {

                    setLoading(true);

                    // Do a screening to check if date fields are 
                    fields.forEach((field) => {

                        if (field.field.includes('date')) {

                            // values[field.field] = new Timestamp(new Date());

                            values[field.field] = moment(values[field.field]).valueOf();

                        } else {

                        }


                        if (field.type === ('time')) {

                            // values[field.field] = new Timestamp(new Date());

                            values[field.field] = moment(values[field.field]).format('HH:mm A');

                        } else {

                        }



                    })

                    onSubmit(values).then(() => {

                        setLoading(false);

                    });
                }}
                onFieldsChange={(fields) => {

                    fields.forEach((field) => {
                        if (field.name[0].includes('date')) {

                            // values[field.field] = new Timestamp(moment(field.value).valueOf());

                            // content[field.name[0]] = moment(field.value).format('DD/MM/YYYY');
                        } else {
                            // content[field.name[0]] = field.value;
                        }
                    })

                    // setContent({ ...content })

                }}
                layout="vertical"
                // validateMessages={validateMessages} 
                initialValues={{
                    ...content,
                    remarks: ''
                }}
            >

                {
                    fields.map((field, key) => {

                        if (field.condition) {
                            return field.condition(content) ? <UserInput onChange={onChange} key={key} onUpload={onUpload} field={field} /> : null

                        } else {
                            return <UserInput onChange={onChange} key={key} field={field} onUpload={onUpload} />;
                        }
                    })
                }

                <Button loading={loading} type="primary" htmlType="submit" className="submit-button">
                    SUBMIT
                </Button>
            </Form>

        </section>
    );
}

export default (FormCreator);



/**
 * Component accepts user input according to type
 * 
 * @param {*} param0 
 */
function UserInput({ field, onUpload, onChange }) {

    let props = {};

    // The extra text
    if (field.extra) {
        props.extra = field.extra;
    }

    /**
    * According to the type render each element
    * 
    * @param {*} field 
    */
    const inputElement = (field, onChange) => {

        switch (field.type) {

            case 'number':
                return <InputNumber />

            case 'input':
                return <Input />

            case 'radio':
                return <Radio.Group>
                    {field.options.map((option, key) => <Radio key={key} value={option}>{option ? 'Yes' : 'No'}</Radio>)}
                </Radio.Group>

            case 'checkbox':
                return <Checkbox.Group style={{ width: '100%' }}>
                    <Col>
                        {
                            field.options.map((option, key) => {

                                let opt = typeof option === 'string' ? option : option.value;

                                return <Row><Checkbox key={key} value={opt}>{opt}</Checkbox></Row>
                            })
                        }
                    </Col>
                </Checkbox.Group>

            // case 'checkbox':
            //     return <Checkbox.Group options={field.options}/>
            case 'textarea':
                return <TextArea rows={4} />

            case 'boolean':
                return <Select style={{ width: 120 }}>
                    {[true, false].map((option, key) => <Option key={key} value={option}>{option ? 'Yes' : 'No'}</Option>)}
                </Select>

            case 'select':
                return <Select style={{ width: 120 }}>
                    {field.options.map((option, key) => <Option key={key} value={option}>{option}</Option>)}
                </Select>

            // case 'checkbox':
            //     return <Checkbox.Group options={field.options} />

            case 'datetime':
                return <DatePicker format={"DD/MM/YYYY"} onChange={(record) => {

                    console.log(record);

                    onChange(field, record.format('DD/MM/YYYY'))

                }} />

            case 'date':
                return <DatePicker format={"DD/MM/YYYY"} />


            case 'time':
                return <TimePicker format={"HH:mm A"} />

            // onChange={(record) => {

            //     console.log(record);

            //     onChange(field, record.format('HH:mm A'))

            // }}


            case 'upload':
                return <FileUpload multiple={field.multiple} maxSize={field.maxSize || 3} callback={(attachment) => onUpload(field, attachment)} onProgress={() => { }} />

            case 'camera':
                return <Camera />


            default:
                return <Input />
        }
    }



    return (<Form.Item {...props} name={field.field} label={field.caption} rules={[{ required: field.required, message: field.placeholder || 'Please enter ' + field.caption },]}>

        {inputElement(field, onChange)}
        {/* <InputElement field={field} /> */}

    </Form.Item>)
}

