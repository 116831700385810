/**
 * 
 * According to the config the launch page 
 * Shows the brand , the welcome message , 
 * the next button according to the config 
 * 
 * For the Questionnaire Form / Feedback Form for which this component was 
 * initially built , it would show the number of questions 
 * 
 */

import React, { useEffect, useContext, useState } from 'react';

import { Typography } from 'antd';


import { Location } from '../../utils'

import { Actions } from '../..';

import { CustomActions } from '../..';

import './launch-page.scss';

const { Title } = Typography;


function LaunchPage({ CustomComponents, CustomModels, appSettings, steps = {}, legacyMode = false, ...props }) {

    const { brandLogo, heroImage, footerLogo, headers } = appSettings;

    const [actions, setActions] = useState([]);

    console.log("Starting Application");

    // Path/ Subdomain of the application
    // let path = Location.path();

    useEffect(() => {

        // path
        return () => {

            // Load the actions for the launch page 
            getActions();

        };

    }, []);


    const launchPageMenu = [{
        id: '0',
    }];

    const getActions = async () => {


        const { actions = [] } = await Actions.get();

        console.log(actions);

        // if (actions) {

        //     setActions(actions);

        // }
    }

    return (
        <div className='launch-page'>

            {/* Left */}
            <div className='left'>


                {/* LEft Top */}

                <div className='left-top'>

                    {/* Brand Logo */}
                    <div className="brand-logo">

                        {/* <Title>
                            Your Feedback

                        </Title> */}

                        <img className="logo-welcome" src={brandLogo} alt="Logo" />
                    </div>
                    {/* Brand Logo Ends */}

                    {/* Welcome Section */}
                    <div className="welcome-message">
                        <h3 className='title'>Welcomes</h3>

                        <p className='greeting'>You have reached the place !</p>
                    </div>
                    {/* Welcome Section Ends */}

                    <CustomActions menu={launchPageMenu} />

                </div>
                {/* LEft Top Ends */}


                {/* Bottom Part */}
                <div className='left-bottom'>

                    <p style={{ marginTop: '20px' }}>
                        <>👋</> <small>
                            {process.env.REACT_APP_landing_quote} . <small>{process.env.REACT_APP_package_version}</small>
                        </small>
                    </p>
                    {/* Bottom Part Ends */}


                    {/* Footer Logo */}
                    <div className="sidebar-footer">

                        {/* <Link to="/register">Create Account</Link> */}

                        <img className="footer-logo" src={footerLogo} alt={'user photograph'} />

                    </div>
                    {/* Footer Logo Ends */}

                </div>




            </div>
            {/* Left Ends */}

            {/* Right */}
            <div className='right'>

                {heroImage && <img className="launch-image" src={heroImage} alt="Logo" />}

            </div>
            {/* Right Ends */}



        </div>
    );
}

export default LaunchPage;
