

import { Link } from 'react-router-dom';

import { Tag } from 'antd';


import ModelDetail from './model-detail/model-detail';

import FirebaseUtils from '../../../utils/firebase.utils';

import { Base } from '../..';



class Models extends Base {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },

            {
                field: 'description',
                caption: 'Description',
            },

            {
                field: 'path',
                caption: 'Path',
            },
        ];

        // this.columns = ;
    }


    /**
     * Get the data from the table
     */
    getMethod(queries = []) {
        // Get the records from firebase
        return FirebaseUtils.getRecords(queries, this);
    }

    get getEndpoint() {
        return 'models';
    }

    get path() {
        return `models`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `model`;
    }

    get columns() {
        return [
            {
                field: 'name',
                caption: 'Name',
            },

            {
                field: 'description',
                caption: 'Description',
            },

            {
                field: 'path',
                caption: 'Path',
            },
        ];
    }

    /**
     * Get the data from the table
     */
    // get = (queries) => {

    //     return this.getMethod(queries).then((result) => {

    //         return Promise.all(result.collections.map(this.loadStaff)).then((result) => {

    //             return { collections: result };

    //         })
    //     })
    // }

    // Attach the related staff with the object


    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    Card = (record) => {
        return (
            <Link className="booking-card" to={`/models/${record['id']}`}>
                <div className="card">
                    <h2 className="">{record.name}</h2>

                    <h3 className="title">{record.description}</h3>

                    <p className="address">
                        Created by {record.created_by}
                        <Tag color="magenta">{record['Consultant Name']}</Tag>
                    </p>
                </div>
            </Link>
        );
    };

    DetailComponent = () => <ModelDetail model={this} />
}

export default Models;
