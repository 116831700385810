


/**
 * Camera Component would save the photo with location
 */

import React, { useState, useEffect, useContext } from "react";


import { Modal, Button, Typography, Empty } from 'antd';

import Camera, { FACING_MODES } from 'react-html5-camera-photo';

import useGeolocation from 'react-hook-geolocation'

import { CameraOutlined, ScanOutlined, LoadingOutlined } from '@ant-design/icons';

import 'react-html5-camera-photo/build/css/index.css';

import './camera.scss';

const { Title } = Typography;

export default function CameraComponent({ value, onChange = () => { } }) {

    const [selected, setSelected] = useState(value);

    const geolocation = useGeolocation({
        enableHighAccuracy: true,
        maximumAge: 15000,
        timeout: 12000
    })

    const [loading, setLoading] = useState(false);

    const [visible, setVisible] = useState(false);

    function handleTakePhoto(dataUri) {
        // Do stuff with the photo...
        // console.log(dataUri, geolocation);

        setSelected(dataUri);

        setVisible(false);

        setLoading(false);

        setSelected(dataUri);

        onChange(dataUri);

        // Attachments.uploadBase64(dataUri).then((result) => {

        //     console.log(result);

        //     // onChange({ result, geolocation });

        //     onChange(result.url);

        //     setLoading(false);

        // })
    }

    /**
     * Clear the local data
     */
    function clear() {

        setSelected(null);
    }


    return (<div className="web-camera">

        <Title level={5}>
            Camera
        </Title>

        {
            !loading
                ?
                <p>
                    Take a picture of the candidate.
                </p>
                :
                <p>

                    Camera On
                    {/* <LoadingOutlined /> */}
                </p>
        }

        <div className="web-camera-container">

            <div className="web-camera-box">

                {/* {selected ? <ImagePreview dataUri={selected} /> : null} */}

                {
                    selected
                        ?
                        <div className="image-container"><ImagePreview dataUri={selected} /> </div>
                        :
                        <div className="">

                            {loading ? <LoadingOutlined /> : <CameraOutlined />}

                        </div>}

            </div>

            <div className="actions">

                <Button type="primary" className="camera-trigger" onClick={() => { setVisible(true); }}>

                    {
                        loading
                            ?
                            <>

                                <LoadingOutlined /> Loading
                            </>
                            :
                            'Take Picture'
                    }



                </Button>

                {loading ? <>

                    <Button onClick={() => {
                        setLoading(false);
                    }}>
                        Cancel
                    </Button>

                </> : null}


                {
                    selected
                        ? <>

                            <Button onClick={() => {

                                setSelected(null);

                                setLoading(false);

                            }}>
                                Clear
                            </Button>

                            <Button onClick={() => {

                                setSelected(false);

                                setVisible(true);

                            }}>
                                Retake
                            </Button>

                        </>
                        :
                        null
                }

            </div>
        </div>

        <Modal
            width={'50%'}
            header={null}
            destroyOnClose
            className="camera-modal"
            visible={visible}
            title={'Camera'}
            footer={null}
            onCancel={() => { setVisible(false); }}
        >

            <Camera
                isImageMirror={false}
                // idealFacingMode={FACING_MODES.ENVIRONMENT}
                onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
            />

            <div className="geotag">
            </div>

        </Modal>

    </div>)
}


function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}



export const ImagePreview = ({ dataUri, isFullscreen }) => {
    let classNameFullscreen = isFullscreen ? 'demo-image-preview-fullscreen' : '';

    return (
        <div className={'demo-image-preview image-preview' + classNameFullscreen}>
            <img src={dataUri} />
        </div>
    );
};