import React, { useState, useContext } from 'react';

import { LeftOutlined } from '@ant-design/icons'

import { GlobalContext } from './../../Store';

import './generic-header.scss';
/**
 * Generic Header to be used across the app,
 * can accept right-text as a props
 */

function GenericHeader(props) {

    const { isMobile, user = {} } = useContext(GlobalContext);

    const { heading, children, onBack, onRightClick, isGenericHeader = true } = props;


    const username = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).StaffName : 'User'

    return (
        <div className="generic-header">
            {
                isGenericHeader ?
                    <div className="main-header">

                        {
                            isMobile
                                ?
                                <>

                                    <LeftOutlined />


                                </>
                                :

                                <>

                                    <div className="cancel-icon" onClick={onBack ? () => onBack() : null}>

                                        <LeftOutlined />
                                    </div>
                                </>
                        }

                        {/* hide on mobile */}



                    </div>
                    :
                    <div className="custom-header">
                        <div className="text"><h4>{heading ? heading : ''}</h4></div>
                        <div className="cancel-icon" onClick={onBack ? () => onBack() : null}>

                            <LeftOutlined />
                        </div>
                    </div>
            }
        </div>
    );
}
export default (GenericHeader)