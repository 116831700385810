/**
 * Generic Detail accepts an array of fields to update any resource
 */

import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Typography, Button, Skeleton, Tabs, Row, Popconfirm, Space } from 'antd';

import { withRouter } from 'react-router-dom';

import './form-detail.scss';

import GenericList from '../../../../modules/generic/generic-list/generic-list';

import { ReloadOutlined } from '@ant-design/icons';

//  import useDeviceDetect from './../../../../../hooks/device-detect';

import { Columns } from '../../../../models';

import ScriptInput from './../../../../components/script-input/script-input';

const { Title } = Typography;

const { TabPane } = Tabs;

/**
 * Generic List
 *
 * @param {*} param0
 */
function FormDetail({ match, model, columns: cols, schema = { custom_actions: [] }, disableEdit = schema.disableEdit }) {

    const [record, setRecord] = useState([]);

    const { params } = match;

    // console.log(params);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData();

        // console.log('hello');
    }, []);

    const loadData = () => {
        setLoading(true);

        getRecords(schema);
    };

    const getRecords = (schema) => {

        // Get the model by queries
        return model.getRecord(match.params.id, model).then((result) => {

            setRecord(result);

            setLoading(false);
        });
    };

    function refresh() {
        loadData();
    }

    /**
     * On Update of script
     * 
     * @param {*} param0 
     * @returns 
     */
    function onUpdate({ script_id, field }) {

        let body = {
            [field]: script_id
        }

        return model.update(params.id, body).then((result) => {

            console.log(result);

            loadData();
        })
    }

    return (
        <section className="generic-list">
            {/* Table Header */}
            <div className="table-header">
                <div className="table-title">
                    <Title level={4}>{model.name}</Title>
                </div>

                <div className="table-actions">
                    <div className="button-container">
                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>

                        {disableEdit ? null : (
                            <Space size="middle">
                                <Button type="dashed">
                                    <Link to={`/${model.name}/${match.params.id}/e/`}>Edit</Link>
                                </Button>
                            </Space>
                        )}
                    </div>
                </div>
            </div>

            {/* Table Header Ends */}

            {loading ? (
                <Skeleton type="listing" />
            ) : (
                <>
                    <div className="card">

                        <div gutter={0} className="detail-wrapper">

                            <div className="detail-element">
                                <span>Name</span>
                                <Row span={6}>
                                    <h3>{record.name}</h3>
                                </Row>
                            </div>

                            <div className="detail-element">
                                <span>Description</span>
                                <Row span={6}>
                                    <h3>{record.description}</h3>
                                </Row>
                            </div>


                            <div className="detail-element">
                                <span>On Submit</span>
                                <Row span={6}>

                                    <Space>

                                        <ScriptInput script_id={record.on_submit_script_id} callback={({ script_id }) => {

                                            onUpdate({ script_id, field: 'on_submit_script_id' });
                                        }} />

                                        {
                                            record.on_submit_script_id
                                                ?
                                                <Popconfirm
                                                    title="Are you sure you want to remove the script ? "
                                                    onConfirm={onUpdate}>
                                                    <Button size={'small'} type="danger">Remove</Button>
                                                </Popconfirm>
                                                :
                                                null
                                        }

                                    </Space>


                                </Row>
                            </div>



                            <div className="detail-element">
                                <span>Before Submit Script</span>
                                <Row span={6}>

                                    <Space>

                                        <ScriptInput script_id={record.before_submit_script_id} callback={({ script_id }) => {

                                            onUpdate({ script_id, field: 'before_submit_script_id' });
                                        }} />

                                        {
                                            record.before_submit_script_id
                                                ?
                                                <Popconfirm
                                                    title="Are you sure you want to remove the script ? "
                                                    onConfirm={onUpdate}>
                                                    <Button size={'small'} type="danger">Remove</Button>
                                                </Popconfirm>
                                                :
                                                null
                                        }

                                    </Space>


                                </Row>
                            </div>

                        </div>
                    </div>

                    <Tabs>
                        <TabPane tab="Form Columns" key="0">
                            <GenericList
                                index={'form_id'}
                                model={Columns}
                                additional_queries={[
                                    {
                                        field: 'form_id',
                                        value: params.id,
                                    },
                                ]}
                            />
                        </TabPane>
                    </Tabs>
                </>
            )}
        </section>
    );
}

export default withRouter(FormDetail);
