/**
 * 
 * 
 * @description Component for Copying the element 
 * @author Ashique
 */


import React, { useState, useEffect, useContext } from 'react';

import { Button, message } from 'antd';


/**
 * 
 */
export default function CopyToClipBoard({ caption = "Element ID", id, record }) {

    /**
     * Copy to clipboard
     * 
     * @param {*} step 
     */
    function copyToClipBoard(id) {

        console.log(record);

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(id);

        message.success(`${caption} copied to Clipboard`);

    }


    return (<>

        <Button size={'small'} onClick={() => { copyToClipBoard(id) }}>
            Copy

        </Button>

    </>)

}