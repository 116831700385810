

import { PostData, GetData, PutData, DeleteData } from './http.utils';

let headers = {}

var settings = {};

export default class ApiUtils {

    static getUser = () => {
        return this.user;
    };

    static get = ({ url, config = { queries: [] }, ...props }) => {

        const { headers } = settings;

        return GetData({
            url: createUrlParams(url, config),
            settings,
            headers,
            ...props
        }).catch((result) => {
            console.log(result);
        });
    };


    static getRecordDetail = ({ url, config = { queries: [] }, ...props }) => {

        const { headers } = settings;

        return GetData({
            url: createUrlParams(url, config),
            settings,
            headers,
            ...props
        }).catch((result) => {
            console.log(result);
        });
    };

    static post = ({ url, formBody, ...props }) => {

        const { headers } = settings;

        return PostData({
            url,
            formBody,
            settings,
            headers,
            ...props
        });
    };

    static put = ({ url, formBody, ...props }) => {

        const { headers } = settings;

        return PutData({
            url,
            settings,
            formBody,
            headers,
            ...props
        });
    };

    static delete = ({ url, formBody, ...props }) => {

        const { headers } = settings;

        return DeleteData({
            url,
            settings,
            headers,
            ...props
        });
    };

    /**
     * Get Auth Status
     * 
     * @param {*} param0 
     * @returns 
     */
    static getAuthStatus = ({ settings: config }) => {

        headers = headers;

        settings = config;

        return GetData({
            url: `auth/profile`,
            settings,

            headers
        });
    };

}

/**
 *
 */
function createUrlParams(url, config) {
    var base_url = url;

    let { queries = [] } = config;

    if (queries.length > 0) {
        base_url += '?';
    }

    queries.forEach((ele) => {
        base_url += `${ele.field}=${ele.value}&`;
    });

    // Remove the last ambersent added
    if (queries.length) {

        base_url = base_url.substring(0, base_url.length - 1)

    }


    if (config.includes) {
        base_url += `&includes=${config.includes}`;
    }

    return base_url;
}
