
import React, { useState, useContext } from "react";

import { Switch } from 'antd';

export default function SwitchComponent({ onChange, value }) {

    return (<div>
        <>
            <Switch
                checked={value}
                onChange={onChange}
                // checked={ }
                listType="picture-card"
            />

        </>

    </div>)
}

