/**
 * Task Form
 */

import React, { useEffect, useState, useContext, Fragment } from "react";

import { Button, Skeleton, Tag, Typography } from 'antd';

import { SyncOutlined } from '@ant-design/icons';

import { Steps, Process, Columns, Scripts, ProcessTransactions, StepTranscations } from '../../..';

import { Location } from '../../../../utils/location.utils';

import { DateUtils } from "../../../../utils";

import TaskStatus from './../task-status/task-status';

import ProcessTimeline from "../process-timeline/process-timeline.js"

import TaskForm from "../task-form/task-form";

import './task-detail.scss';

import { GlobalContext } from './../../../../Store';

const { Title } = Typography;

let baseModels = require('./../../../../models');

export default function TaskDetail({ match, history }) {

    let { record_id, process_transaction_id } = Location.search();

    const [form, setForm] = useState({});

    const [step, setStep] = useState({})

    const [record, setRecord] = useState({});

    const [model, setModel] = useState({});

    const { CustomModels } = useContext(GlobalContext);

    let models = {
        ...baseModels,
        ...CustomModels
    }

    const { params } = match;

    const { step_id, id } = params;

    const [loader, setLoader] = useState(true)

    useEffect(() => {

        // loadData();

        loadStepConfiguration();

    }, []);

    /**
     * Load the step configuration
     */
    async function loadStepConfiguration() {

        const result = await Process.getProcessTimelineForStep(record_id, step_id, process_transaction_id);

        setStep(result);

        let instance = models[result.model_identifier]

        // setModel(instance);

        // Load the model data
        loadModelData({ model: instance, record_id, process_transaction_id }).then(() => {

            if (result.form_id) {

                // If there is a form attached 
                // result.form_id && loadForm(result);

                setLoader(false);

            } else {

                setLoader(false);

            }

        });

    }

    function loadData() {

        setLoader(true);

        Steps.getRecord(step_id).then((result) => {

            setStep(result);

            let instance = models[result.model_identifier]

            // setModel(instance);

            // Load the model data
            loadModelData({ model: instance, record_id, process_transaction_id }).then(() => {

                if (result.form_id) {

                    // If there is a form attached 
                    // result.form_id && loadForm(result);

                    setLoader(false);

                } else {

                    setLoader(false);

                }

            });
        })
    }


    /**
     * Load the model data
     * 
     * @param {*} param0 
     * @returns 
     */
    async function loadModelData({ record_id, process_transaction_id }) {

        // Process transaction
        const process_transaction = await ProcessTransactions.getRecord(process_transaction_id);

        // 
        let model = models[process_transaction.model_identifier];

        setModel(model);

        return model.getRecord(record_id).then((result) => {

            setRecord(result);
        })
    }

    const { step_transactions = null } = step;

    return (
        <div className="task-form">

            {/* <Header onBack={() => props.history.goBack()} /> */}

            {
                loader ?
                    <Skeleton type="report" />
                    :
                    <Fragment>

                        <>

                            <div className="about-us page-content">
                                <h1 className="page-heading">
                                    {step.name}
                                </h1>
                            </div>
                            <p>
                                {step.description}
                            </p>

                            {model && <model.Card record={record} />}


                            {/* Show the process timeline */}
                            <ProcessTimeline record={record} step={step} />
                            {/* Show the process timeline Ends */}

                            <div gutter={0} className="detail-wrapper card">

                                {/* Checklist  */}
                                {step.checklists && step.checklists.length ? <>

                                    <div className="checklist">

                                        <Title level={4}>
                                            Checklists
                                        </Title>

                                        <p>
                                            Ensure to follow the below steps on completion of this step.
                                        </p>

                                        {step.checklists.map((entry, index) => {
                                            return (<div className="step">


                                                <h4>
                                                    {index + 1}. {entry.title}
                                                </h4>

                                                <p>
                                                    {entry.description}
                                                </p>

                                            </div>)

                                        })}

                                    </div>

                                </> : null}

                                {/* Checklist  Ends */}

                                {/* Summary of Step Activity */}
                                <TaskStatus step={step} step_transactions={step_transactions} />
                                {/* Summary of Step Activity Ends */}


                                <div className="step-form-container">
                                    <TaskForm record_id={record_id} step={step} callback={() => {

                                        history.goBack();


                                    }} />
                                </div>

                            </div>
                        </>
                    </Fragment >}
        </div >
    )
}

