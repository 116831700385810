import Base from './base';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

class BaseLoader extends Base {
    constructor(model = {}) {

        super(model);


        this.id = model.id;

        this.name = model.path;

        this.endpoint = this.name;

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'amount',
                caption: 'Amount',
            },
        ];
    }

    get filterDate() {
        return true;
    }

    get getEndpoint() {
        return this.name;
    }

    get path() {
        return this.name;
        // return `collections`;
    }

    get getName() {
        return this.name;
    }

    get modelName() {
        return this.name;
    }

    get columns() {
        return [
            {
                caption: 'Staff',
                field: 'staff.name',
                key: 'staff',
            },
            {
                caption: 'Weight',
                field: 'weight',
                key: 'weight',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    Card = (record) => {
        return (
            <Link className="booking-card" to={`/collections/${record['id']}`}>
                <div className="card">
                    <h2 className="">{record.staff ? record.staff.name : null}</h2>

                    <h3 className="title">{record.weight}</h3>

                    <p className="address">
                        Collected on {record.created_at}
                        <Tag color="magenta">{record['Consultant Name']}</Tag>
                    </p>
                </div>
            </Link>
        );
    };
}

export default BaseLoader;
