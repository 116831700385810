import Base from './../base';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

// import MenuRolesAdd from '../business/common/resources/menu-roles-add/menu-roles-add';

import LookupDetail from '../../models/lookup-types/components/lookup-detail/lookup-detail';

import { LookupValues } from '../../../core/models/';


class LookupType extends Base {

    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'description',
                caption: 'Description',
            },
        ];

        // this.columns = ;
    }

    get getEndpoint() {
        return 'lookup-types';
    }

    get path() {
        return `lookup-types`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `lookup-type`;
    }

    get columns() {
        return [
            {
                caption: 'Name',
                field: 'name',
                key: 'name',
            },
            {
                caption: 'Description',
                field: 'description',
                key: 'description',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    getValues(id) {

        var queries = [{
            field: 'lookup_type_id',
            value: id
        }]

        return LookupValues.get(queries)
    }

    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    Card = (record) => {
        return (
            <Link className="booking-card" to={`/collections/${record['id']}`}>
                <div className="card">
                    {/* <h2 className="">{record.role.name}</h2> */}

                    <h3 className="title">{record.weight}</h3>

                    <p className="address">
                        Collected on {record.created_at}
                        <Tag color="magenta">{record['Consultant Name']}</Tag>
                    </p>
                </div>
            </Link>
        );
    };

    // AddComponent = () => <CollectionAdd model={this} staff={Staffs} />;

    // ModalAddComponent = (props) => <MenuRolesAdd model={this} {...props} />;

    DetailComponent = () => <LookupDetail model={this} />;

}

export default LookupType;
