import Base from './../base';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

class Department extends Base {

    constructor() {
        super();

        this.fields = [
            {
                field: 'description',
                caption: 'Description',
            },

            {
                field: 'location',
                caption: 'Location',
            },
        ];

        // this.columns = ;
    }

    get getEndpoint() {
        return 'departments';
    }

    get path() {
        return `departments`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `department`;
    }

    disableAddModal() {
        return false;
    }

    disableAdd() {
        return false;
    }


    get columns() {
        return [
            // {
            //     caption: 'Caption',
            //     field: 'caption',
            //     key: 'caption',
            // },

            // {
            //     caption: 'Field',
            //     field: 'field',
            //     key: 'field',
            // },
            {
                caption: 'Description',
                field: 'description',
                key: 'description',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }


    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    Card = (record) => {
        return (
            <Link className="booking-card" to={`/collections/${record['id']}`}>
                <div className="card">
                    <h2 className="">{record.caption}</h2>

                    <h3 className="title">{record.description}</h3>

                    <p className="address">
                        Collected on {record.created_at}
                        <Tag color="magenta">{record['Consultant Name']}</Tag>
                    </p>
                </div>
            </Link>
        );
    };

}

export default Department;
