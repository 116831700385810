import React, { useEffect, useState, useContext } from 'react';

import { Form, Input, Button, Select, Typography } from 'antd';

import { GlobalContext } from '../../../../Store';

import { Roles, Menus } from '../../../../models';

import { useParams } from 'react-router-dom';

const { Title } = Typography;

const { Option } = Select;

/**
 *
 *
 * @param {*} param0
 * @returns
 */
export default function MenuRolesAdd({ model, callback, formContent, ...props }) {

    // Get the firestore app
    const app = Menus.getFireStoreApp();

    let arr = []

    const [tree, setTree] = useState([]);

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [roles, setRoles] = useState([]);

    const { user } = useContext(GlobalContext);

    const id = useParams().id;

    useEffect(() => {

        getMenuTree(id).then((result) => {

            console.log(result);

        });


        getRoles();

    }, []);

    /**
     * Get the menu and its parents
     * 
     * @param {*} id 
     */
    async function getMenuTree(id) {

        console.log("Loading tree of ", id);

        return new Promise(async (resolve, reject) => {

            let record = await Menus.getRecord(id);

            // Push the record to the tree
            arr.push(record);

            if (record.header_id) {

                console.log("Found header", record.header_id);

                return await getMenuTree(record.header_id);

            } else {

                console.log(arr);

                setTree(arr);

                resolve(arr);
            }
        })
    }

    /**
     * Load the Staffs
     */
    const getRoles = () => {
        Roles.get([]).then((result) => {
            console.log(result);

            setRoles(result.roles);
        });
    };

    /**
     * On Submit
     * 
     * @param {*} values 
     */
    const onSubmit = async (values) => {

        console.log(values);

        values.menu_id = id;

        setLoading(true);

        // Starting a batched write
        let batch = app.batch();

        // Get the model reference to start with write
        // var modelRef = model.getRecordReference();

        // Update the reference with values
        // await batch.set(modelRef, values);

        // For each parent menu , activate the menu
        await Promise.all(tree.map(async (menu) => {

            var modelRef = model.getRecordReference();

            var queries = [{
                field: 'menu_id',
                value: menu.id
            }, {
                field: 'role_id',
                value: values.role_id
            }]

            let existing_roles = await model.get(queries)

            if (existing_roles && existing_roles.menu_roles.length) {


            } else {

                // Menu Id and the role
                values.menu_id = menu.id

                // Update the reference with values
                return await batch.set(modelRef, model.appendDefaultValues(values));

            }
        }))

        await batch.commit()

        callback();

        setLoading(false);

        // model.add(values, user).then(() => {


        // });
    };

    return (
        <section className="collection-add">
            <Title level={4}>Add Menu</Title>

            <Form form={form} initialValues={formContent} layout="vertical" onFinish={onSubmit}>
                {/* Model */}
                <Form.Item label="Roles" name="role_id" required>
                    <Select style={{ width: '100%' }}>
                        {roles.map((model) => (
                            <Option value={model.id}>{model.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                {/* Model Ends */}

                <Form.Item>
                    <Button loading={loading} htmlType={'submit'} type="primary">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </section>
    );
}
