import React from 'react'
import { Result, Button } from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import "./success.scss"


import {

  Location,

} from "@soxo/firebase-core";
function Success() {

  const handleRouting = () => {


    Location.back()
  };
  return (
    <div className='result'>


      <Result
        status="success"
        title="Successfully Registerd!"

        extra={[


          <Button type="primary" key="console" onClick={handleRouting}>
            Back
          </Button>,


        ]}
      />
    </div>
  )
}

export default Success