import React, { useState, useEffect, useContext } from 'react';

import { GlobalContext } from './../../Store';

import { Link, useLocation } from 'react-router-dom';

import { Typography, Button, Avatar } from 'antd';

import GenericHeader from '../header/generic-header';

import { MenuOutlined, UserOutlined, SettingOutlined, DatabaseOutlined, DoubleLeftOutlined } from '@ant-design/icons';

import { Row, Col, Drawer } from 'antd';

import { ReloadOutlined } from '@ant-design/icons';

import SideMenu from './../sidemenu/sidemenu';

import './global-header.scss';

import useWindowSize from './../../hooks/use-window-size';


const { Title } = Typography;

export default function GlobalHeader({ loading, appSettings, children, isConnected, history, modules = [], sidemenu = [], reload, meta = {} }) {

    let location = useLocation();
    // let location = {};

    const { isMobile, user = { locations: [] } } = useContext(GlobalContext);

    const [visible, setVisible] = useState(false);

    const [sidebar, setSidebar] = useState(true);

    const size = useWindowSize();

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const toggleCollapsed = () => {
        setSidebar(!sidebar);
    }

    const { model: BaseModel = {}, menu = {} } = meta;

    const { model = {} } = menu;

    return (
        <div className={`global-header ${isConnected ? 'connected' : ''}`} style={{ height: 10 }}>
            {/* <MenuOutlined style={{left:'1%',top:'1%', fontSize:18, position:'absolute', zIndex:999}} onClick={showSidebar}/> */}
            <div className="layout-content">
                {/* <Button onClick={toggleCollapsed} style={{ margin: 10, position: 'absolute' }}>
                    {sidebar ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
                </Button> */}

                <div className={`left-bar ${!isConnected ? '' : 'hide'}`}>

                    {!isMobile && !isConnected ? (
                        <div className="sidebar-container">
                            <SideMenu loading={loading} appSettings={appSettings} callback={onClose} modules={modules} history={history} />
                        </div>
                    ) : (
                        <Drawer placement="left" onClose={onClose} visible={visible} className="drawer-content">
                            <div className="sidebar-container">
                                <SideMenu loading={loading} appSettings={appSettings} callback={onClose} modules={modules} history={history} />
                            </div>
                        </Drawer>
                    )}

                </div>


                <div className="right-section">

                    {/* <div className="toggler">
                        <CaretRightOutlined style={{ fontSize: 18, margin: 5 }} onClick={showSidebar} />
                    </div> */}


                    {
                        !isConnected
                            ?
                            <Row className="page-wrapper">
                                <Col>
                                    {location.pathname.indexOf('admin') !== -1 ? (
                                        <span onClick={showDrawer} className="toggle-box toggle-menu">
                                            <MenuOutlined />
                                            <Title level={4}>{/* {menu.caption} */}</Title>
                                        </span>
                                    ) : (
                                        <>
                                            <span
                                                className="toggle-box"
                                                onClick={() => {
                                                    history && history.goBack();
                                                }}
                                            >
                                                <GenericHeader />
                                                {/* <Title level={4}>
                                            </Title> */}
                                            </span>
                                        </>
                                    )}
                                </Col>

                                {user.role || user.id ? (
                                    <div className="page-menu">

                                        {/* <ModalSearch /> */}

                                        {
                                            user.isAdmin
                                                ?
                                                <>


                                                    {/* Models */}
                                                    {menu && menu.id ? <Link to={`/menus/${menu.id}`}>
                                                        <Button type="secondary" size={'small'}>
                                                            <SettingOutlined />
                                                        </Button>
                                                    </Link> : null}
                                                    {/* Models Ends */}

                                                    {/* Models */}
                                                    {model && model.id ? <Link to={`/models/${model.id}`}>
                                                        <Button type="secondary" size={'small'}>
                                                            <DatabaseOutlined />
                                                        </Button>
                                                    </Link> : null}

                                                    {/* Models Ends */}

                                                </> : null}


                                        <Button onClick={reload} type="secondary" size={'small'}>
                                            <ReloadOutlined />
                                        </Button>

                                        <ProfileAvatar />

                                    </div>
                                )
                                    :
                                    null
                                }

                            </Row>

                            :
                            null
                    }


                    {children}
                </div>
            </div>
        </div>
    );
}


function ProfileAvatar() {
    const { user = { locations: [] } } = useContext(GlobalContext);

    useEffect(() => { }, []);

    return (
        <Link className="profile-avatar" to="/admin/profile">
            {user.photograph ? (
                <>
                    <img className="profile-picture" src={user.photograph} alt={'user photograph'} />
                </>
            ) : (
                <Avatar shape="square" size="small" icon={<UserOutlined />} />
            )}

            {/* {user.name} */}
        </Link>
    );
}
