







/**
 * 
 * @param {*} records 
 * @param {*} columns 
 * @returns 
 */
export function getExportData(records, columns) {

    let filteredColumns = columns.filter((col) => !col.disableExport)

    // Iterate each record and map with the columns
    return records.map((row, index) => {

        let entry = {
            'Sl No': index + 1
        };

        filteredColumns.forEach((column, index) => {

            // Data Index / Field
            if (column.exportDefinition) {

                entry[column.title] = column.exportDefinition(row, index, index);

            } else if (column.render) {

                entry[column.title] = column.render(row, index, index);

            } else if (column.dataIndex) {

                entry[column.title] = row[column.dataIndex];

            } else if (column.field) {

                entry[column.title] = row[column.field];

            } else {

                entry[column.title] = row[column.field];

            }
        })

        return entry;

    })
}