import React, { useEffect, useState, useContext } from 'react';

import { Form, Input, Button, Select, Skeleton, InputNumber } from 'antd';

import { GlobalContext } from '../../../../Store';

import { Switch, JSONInput } from './../../../../elements/'

import { Pages, Models, Menus } from './../../../../models';

import { Location } from '../../../../utils/location.utils';

const { Option } = Select;

export default function MenuAdd({ model, edit, history, match, formContent, callback, additional_queries, ...props }) {

    let step = Location.search().step || 1;

    const [loading, setLoading] = useState(true);

    const [form] = Form.useForm();

    const [pages, setPages] = useState([]);

    const [models, setModels] = useState([]);

    // const [modules, setModules] = useState([]);

    const [body, setBody] = useState(formContent);

    const { user } = useContext(GlobalContext);

    const { params } = match;

    useEffect(() => {
        getPages();

        getModels();

        // getModules();

        // if (params && params.id) {
        //     getRecord(params.id);
        // } else {
        setLoading(false);
        // }
    }, []);

    function getRecord(id) {
        // console.log(id);

        Menus.getDetail(id).then((result) => {

            console.log(result);
            setBody(result);

            setLoading(false);
        });
    }

    /**
     * Load the Staffs
     */
    const getPages = () => {
        Pages.get([]).then((result) => {
            // console.log(result);

            setPages(result.pages);
        });
    };

    /**
     * Load the Staffs
     */
    const getModels = () => {
        Models.get([]).then((result) => {
            // console.log(result);

            setModels(result.models);
        });
    };

    /**
     * Load the Staffs
     */
    // const getModules = () => {
    //     Modules.get([]).then((result) => {
    //         // console.log(result);

    //         setModules(result.modules);
    //     });
    // };

    const onSubmit = (values) => {

        setLoading(true);

        if (formContent.id) {
            // Update of model
            model.update(formContent.id, values, user).then(() => {

                callback();

                setLoading(false);

            });

        } else {

            values['header_id'] = null;

            values.step = parseInt(step);

            // Append the additional queries to the object
            additional_queries.forEach(({ field, value }) => {

                values[field] = value;

            })

            // add new model
            model.add(values, user).then(() => {

                callback();

                setLoading(false);

            });
        }
    };

    return (
        <section className="collection-add">
            {/* <Title level={4}>{edit ? 'Edit' : 'Add'} Menu</Title> */}

            {loading ? (
                <Skeleton />
            ) : (
                <Form initialValues={{
                    ...body
                }} form={form} layout="vertical" onFinish={onSubmit}>

                    {/* Name */}
                    <Form.Item name="name" label="Name" required>
                        <Input placeholder="Enter name" />
                    </Form.Item>
                    {/* Name Ends */}

                    {/* Model */}
                    {/* <Form.Item label="Module" name="module_id" required>
                        <Select style={{ width: '100%' }}>
                            {modules.map((model, key) => (
                                <Option key={key} value={model.id}>{model.name}</Option>
                            ))}
                        </Select>
                    </Form.Item> */}
                    {/* Model Ends */}

                    {/* Model */}
                    <Form.Item label="Model" name="model_id">
                        <Select style={{ width: '100%' }}>
                            {models.map((model, key) => (
                                <Option key={key} value={model.id}>{model.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {/* Model Ends */}

                    {/* Page */}
                    <Form.Item label="Page" name="page_id">
                        <Select style={{ width: '100%' }}>
                            {pages.map((page, key) => (
                                <Option key={key} value={page.id}>{page.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {/* Page Ends */}

                    {/* Weight */}
                    <Form.Item name="path" label="Path" required>
                        <Input placeholder="Enter path" />
                    </Form.Item>
                    {/* Weight Ends */}

                    {/* Weight */}
                    <Form.Item name="is_visible" label="Visible" required>
                        <Switch />
                    </Form.Item>
                    {/* Weight Ends */}

                    {/* Weight */}
                    <Form.Item name="finger_print_protected" label="Finger Print Protected" required>
                        <Switch />
                    </Form.Item>
                    {/* Weight Ends */}

                    {/* Order */}
                    <Form.Item name="order" label="Order" required>
                        <InputNumber style={{ width: '100%' }} placeholder="Enter order" />
                    </Form.Item>
                    {/* Order Ends */}

                    {/* Order */}
                    <Form.Item name="redirect_link" label="Redirect Link" required>
                        <Input style={{ width: '100%' }} placeholder="Redirect Link" />
                    </Form.Item>
                    {/* Order Ends */}

                    {/* Attributes */}
                    <Form.Item name={"attributes"} label="Attributes">

                        <JSONInput />

                    </Form.Item>
                    {/* Attributes Ends */}



                    <Form.Item>
                        <Button loading={loading} htmlType={'submit'} type="primary">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </section>
    );
}
