
import React, { useState, useEffect, useContext } from 'react';

import { GlobalContext } from './../../Store';

import { Menu, message, Skeleton } from 'antd';

import FirebaseUtils from './../../utils/firebase.utils';

import { useHistory } from 'react-router-dom';

import { Menus } from '../../models';

import './sidemenu.scss';


const { SubMenu } = Menu;

export default function SideMenu({ loading, modules = [], callback, appSettings, match }) {

    const { brandLogo, footerLogo } = appSettings;

    let history = useHistory();

    console.log(match, history);


    // let basePath = history.location.pathname.split('/')[1];

    // #TODO Base path should come from config ideally
    // let basePath = history.location.pathname.split('/')[1];
    let basePath = '/admin'

    const [selected, setSelected] = useState([1]);

    const [menu, setMenu] = useState({});

    const { user = { locations: [] }, dispatch } = useContext(GlobalContext);

    // const [loading, setLoading] = useState(loading);

    useEffect(() => {

        // Here we have to consider three cases now , 

        // One is firebase for which it is primarly designed for 
        // Then Nura Old Schema 
        // Saudi New Schema 

        // Bringing all to one structure is the ideal

        // if (user.role || user.ID) {
        //     setLoading(false);
        // }

    }, [user]);

    /**
     * Logout Function
     */
    async function handleLogout() {
        localStorage.clear();

        if (process.env.REACT_APP_PRIMARY_BACKEND === 'SQL') {

            // const result = Users.logout()
            localStorage.clear();

            let userInfo = {
                dms: {},
                locations: [],
                ...{ loggedCheckDone: true }
            }

            dispatch({ type: 'user', payload: userInfo });

            history.push('/admin/login');

            message.success('You have logged out successfully.');

        } else {


            FirebaseUtils.logout().then(() => {
                history.push('/admin/login');

                message.success('You have logged out successfully.');
            });
        }
    }

    /**
     * On Click of Menu
     * 
     * @param {*} menu 
     * @param {*} index 
     */
    const onMenuClick = (menu, index) => {

        setSelected([index]);

        let pathToRedirect = `${basePath}${menu.path}`;

        history.push(pathToRedirect);

        setMenu(menu);

        callback();
    };

    return (
        <div className='sidemenu'>
            <div className="intro">

                {/* Logo Bar */}
                <div className="logo-wrapper">
                    <img
                        onClick={() => {
                            history.push('/admin/');
                        }}
                        className="logo-welcome"
                        src={brandLogo}
                        alt="Logo"
                    />

                    <small>{process.env.REACT_APP_package_version}</small>
                </div>
                {/* Logo Bar Ends */}

            </div>


            {/* Search for Queries */}

            {/* <div className="menu-search">

                <small level={5}>Search</small>

                <Input placeholder="Search" />
            </div> */}

            {/* Search for Queries Ends */}

            {loading ? (
                <Skeleton />
            ) : (
                <Menu
                    // selectedKeys={[selected]}
                    // style={{ width: 256 }}
                    // defaultSelectedKeys={selected}
                    // defaultOpenKeys={['']}
                    mode="inline"
                    theme={''}
                >

                    <Menu.Item
                        onClick={() => {
                            setSelected([1]);

                            history.push('/admin/');

                            setMenu({ caption: 'Home' });

                            // setVisible(false);

                            callback();
                        }}
                        key="home-menu"
                    >
                        Home
                    </Menu.Item>

                    {/* 
                    {Menus.orderArray(modules, 'order').filter((record) => {

                        if (record.id) {

                            if (record.is_visible) {
                                return true;
                            } else {
                                return false;
                            }

                        } else {

                            return true;
                        }

                    }).map((menu, index) => {

                        return <MenuItem history={history} menu={menu} index={index} />
                    })
                    } */}



                    {Menus.screenMenus(modules, 'order').filter((record) => {

                        if (record.id) {

                            if (record.is_visible) {
                                return true;
                            } else {
                                return false;
                            }

                        } else {

                            return true;
                        }

                    }).map((menu, index) => {

                        // return <MenuItem menu={menu} index={index} />

                        let sub_menus = menu && menu.sub_menus ? Menus.screenMenus(menu.sub_menus) : [];

                        if (menu && sub_menus && sub_menus.length) {

                            // console.log(menu.id);

                            let randomIndex = parseInt(Math.random() * (10000000000));

                            return (
                                <SubMenu
                                    key={`first-level-${randomIndex}-${menu.caption}`}
                                    title={
                                        <span>
                                            <span>{menu.caption}</span>
                                        </span>
                                    }
                                >
                                    {sub_menus.map((submenu, innerIndex) => {

                                        let randomIndex = parseInt(Math.random() * (10000000000));

                                        let third_menus = submenu && submenu.sub_menus ? Menus.screenMenus(submenu.sub_menus) : []

                                        if (third_menus && third_menus.length) {
                                            return (
                                                <SubMenu
                                                    key={`second-level-${randomIndex}-${submenu.id}`}
                                                    title={
                                                        <span>
                                                            <span>{submenu.caption}</span>
                                                        </span>
                                                    }
                                                >
                                                    {third_menus.map((menu) => {

                                                        let randomIndex = parseInt(Math.random() * (10000000000));

                                                        return (
                                                            <Menu.Item
                                                                onClick={() => {
                                                                    onMenuClick(menu, index);
                                                                }}
                                                                key={`second-level-${randomIndex}-${index}`}
                                                            >
                                                                {menu.caption}
                                                            </Menu.Item>
                                                        );
                                                    })}
                                                </SubMenu>
                                            );
                                        } else {

                                            let randomIndex = parseInt(Math.random() * (10000000000));

                                            return (
                                                <Menu.Item
                                                    onClick={() => {
                                                        onMenuClick(submenu, index);
                                                    }}
                                                    key={`first-level-${randomIndex}-${innerIndex}`}
                                                >
                                                    {submenu.caption}
                                                </Menu.Item>
                                            );
                                        }
                                    })}
                                </SubMenu>
                            );
                        } else {

                            let randomIndex = parseInt(Math.random() * (10000000000));

                            return (
                                <Menu.Item
                                    onClick={() => {
                                        onMenuClick(menu, index);
                                    }}
                                    key={`${menu.id}-${randomIndex}`}
                                >
                                    {menu.caption}

                                </Menu.Item>
                            );
                        }
                    })}

                    <Menu.Item onClick={handleLogout} key="logout-button">
                        <span>Logout</span>
                    </Menu.Item>
                </Menu>

            )}

            <div className="sidebar-footer">

                <img className="footer-logo" src={footerLogo} alt={'user photograph'} />

            </div>
        </div>
    );
}





/**
 * The Menu Item Takes a menu and creates more
 * sub menus
 */
function MenuItem({ menu, index, history }) {

    function renderMenus({ menu, index }) {

        if (menu && menu.sub_menus && menu.sub_menus.length) {

            return (<SubMenu
                key={menu.id}
                title={
                    <span>
                        <span>{menu.caption}</span>
                    </span>
                }>

                {
                    menu.sub_menus.map((menu, index) => {
                        return (
                            <MenuItem menu={menu} index={menu.id} />
                        );
                    })
                }

            </SubMenu>)

        } else {

            return <Menu.Item onClick={() => {

                history.push(menu.path);

            }} key={`${menu.id}`}>
                {menu.caption} {menu.id}
            </Menu.Item>

        }

    }

    return (renderMenus({ menu, index }))

}