import React, { useEffect, useContext, useState } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


// import

import {
    GlobalHeader,
    Landing,
} from './../';

import { Login, Register } from './../../pages/'

import {
    GlobalContext,
    GlobalProvider,
} from '../../Store';

import { Location } from './../../utils/'

import ApplicationBootstrap from './../application-bootstrap/application-bootstrap';

// import * as CustomComponents from './business/'

// import './App.scss';

// import '/root-application.scss';

// import 'antd/dist/antd.css';



function RootApplication({ children, ...props }) {

    const { appSettings = {}, CustomComponents = [], CustomModels = [] } = props;

    console.log("Starting Application");

    useEffect(() => {
        return () => { };
    }, []);

    return (
        <ApplicationBootstrap {...props} >

            {/* <Route exact path="/admin/">

                <Landing
                    steps={props.steps}
                    appSettings={appSettings}
                    CustomComponents={CustomComponents}
                    CustomModels={CustomModels}
                />

            </Route> */}

            {children}


            <Route
                // exact
                path=""
                // component={Landing}
                render={(routeParams) => <Landing

                    steps={props.steps}

                    appSettings={appSettings}
                    CustomComponents={CustomComponents}
                    CustomModels={CustomModels}
                    {...routeParams}
                />}
            ></Route>

            {/* Login Page */}
            <Route exact path="/admin/login" render={(routeParams) => {

                return <Login appSettings={appSettings} {...routeParams} />
            }} />
            {/* Login Page Ends */}


        </ApplicationBootstrap>
    );
}




// function Routes({ history, CustomComponents, appSettings, CustomModels, steps }) {

//     const [loader, setLoader] = useState(false);

//     const { user = {}, twilio } = useContext(GlobalContext);

//     useEffect(() => {
//         Location.getHistoryMethod(getRouterProps);
//     }, []);

//     useEffect(() => {

//         // console.log(user);

//         if (user && user.loggedCheckDone) {

//             // Redirect to login page
//             setLoader(false);

//             if (!user.id) {

//                 // history.push('/login');
//             }
//         }
//     }, [user]);


//     const getRouterProps = () => ({ history: history });


//     useEffect(() => {
//         return () => { };
//     }, []);

//     return (
//         <div className={`app`}>
//             <div className={`content ${twilio ? 'connected' : ''}`}>
//                 {/* Onboarding welcome card */}
//                 {!loader && user.loggedCheckDone ? (
//                     <div>
//                         <Switch>

//                             {/* Login Page */}
//                             <Route exact path="/admin/login" render={(routeParams) => {

//                                 return <Login appSettings={appSettings} {...routeParams} />
//                             }} />
//                             {/* Login Page Ends */}

//                             {/* Register Page */}
//                             {/* <Route exact path="/register" render={(routeParams) => {

//                                 return <Register appSettings={appSettings} {...routeParams} />
//                             }} /> */}
//                             {/* Register Page Ends */}

//                             <Route
//                                 // exact
//                                 path="/admin/"
//                                 // component={Landing}
//                                 render={(routeParams) => <Landing

//                                     steps={steps}

//                                     appSettings={appSettings}
//                                     CustomComponents={CustomComponents}
//                                     CustomModels={CustomModels}
//                                     {...routeParams}
//                                 />}
//                             ></Route>
//                         </Switch>
//                     </div>
//                 ) : (
//                     <div>
//                         <GlobalHeader appSettings={appSettings}
//                         />
//                         {/* <PlaceHolder type="listing" /> */}
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// }




export default RootApplication;
