import React, { useState, useEffect, useContext } from 'react';

import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';

import { Skeleton } from 'antd';

import GlobalHeader from '../../../core/components/global-header/global-header';

import MenuTemplate from '../menu-template/menu-template';

import './landing.scss';

import { GlobalContext } from './../../Store';

import HomePage from './../../../core/pages/homepage/homepage';

import ModuleRoutes from './../../../core/modules/modules-routes/module-routes';

import TaskList from './../../../core/models/process/components/task-list/task-list';

import TaskDetail from './../../../core/models/process/components/task-detail/task-detail';

import { ChangePassword } from '../..';

import ApprovalList from './../../components/approval-list/approval-list';

import ApprovalForm from './../../components/approval-form/approval-form';

import TaskForm from './../../../core/models/process/components/task-form/task-form';

// import WaitingRoom from '../../../business/common/components/waiting-room/waiting-room';

// import Twilio from '../../../business/common/components/twilio/twilio';

import Profile from '../../../core/pages/profile/profile';


// Maintaining a temporary task routes compeontns for mapping tests
import TaskRoutes from './../../models/process/components/task-routes/task-routes.js'



import {
    Models,
    Pages,
    Menus,
    Roles,
    Permissions,
    Process,
    // Appointments,
    Users,
    Modules,
    LookupTypes,
    Forms,
    Steps,
    Actions,

    FinancialYears,
    Branches,
    Departments,
    InvoiceNumbers

}
    from '../../../core/models';

// import { Questions, } from '../../../models';

import { message } from 'antd';

import { useHistory, useParams } from 'react-router-dom';

/**
 * Landing file for
 *
 * @param {*} props
 * @returns
 */
export default function Landing({ history, steps = {}, CustomModels, CustomComponents, appSettings }) {

    let basePath = '/admin';

    // let basePath = history.location.pathname.split('/')[1];

    // let basePath = history.split('/')[1];
    //
    const [tasks, setTasks] = useState([]);

    const [loader, setLoader] = useState(true);

    const [modules, setModules] = useState([]);

    const [connected, setConnected] = useState();

    const { user = {}, dispatch } = useContext(GlobalContext);

    // dispatch({})
    const [meta, setMeta] = useState({});

    var config = {};

    const { Questions } = CustomModels;

    useEffect(() => {

        if (user.role && user.role.name) {

            loadMenus();

            var role = user.role.name.toLowerCase();

            // 
            if (steps[role]) {

                setTasks(steps[role]);
            }
        } else {

            setLoader(false);

        }

    }, []);

    /**
     * 
     */
    const loadMenus = async () => {

        setLoader(true);

        const result = await Menus.getMenus(user);

        console.log(result);

        setModules(result);

        setLoader(false);
    }


    let core_modules = [{
        caption: 'Preferences',
        sub_menus: [{
            path: '/change-password',
            caption: 'Change Password'
        }]
    }]

    if (user.isAdmin) {


        core_modules = Menus.getCoreMenus();
    }

    return (
        <section className="landing">

            <GlobalHeader loading={loader} appSettings={appSettings} meta={meta} isConnected={connected} reload={loadMenus} modules={[...modules, ...core_modules]} user={user} history={history}>


                {
                    loader
                        ?
                        <Skeleton />
                        :
                        <>

                            {/* <Router> */}

                            {/* <Switch> */}

                            <Route exact key={'home'} path={`${basePath}/`} render={(props) => <HomePage steps={tasks} config={config} {...props} />} />

                            <Route exact key={'profile'} path={`${basePath}/profile`} render={(props) => <Profile {...props} />} />


                            {/* <Route exact key={'profile'} path={`${basePath}/menus`} render={(props) => <Profile {...props} />} /> */}

                            {/* <Route exact key={'profile'} path={`${basePath}/menus`} render={(routeParams) => <ModuleRoutes {...routeParams} model={Menus} />} /> */}


                            {/* <Switch> */}
                            {/* Modules and Menus */}
                            <DefineRoute menus={modules} CustomModels={CustomModels} CustomComponents={CustomComponents} callback={(props) => {

                                setMeta({
                                    ...props
                                })

                            }} />

                            {/* Modules and Menus Ends */}
                            {/* </Switch> */}


                            {/* Only Admins will have access to the below routes */}



                            {
                                user.isAdmin
                                    ?
                                    <>

                                        <Route path={`${basePath}/menus`} render={(routeParams) => <ModuleRoutes {...routeParams} model={Menus} />} />

                                        <Route path={`${basePath}/models`} render={(routeParams) => <ModuleRoutes {...routeParams} model={Models} />} />

                                        <Route path={`${basePath}/pages`} render={(routeParams) => <ModuleRoutes {...routeParams} model={Pages} />} />

                                        <Route path={`${basePath}/roles`} render={(routeParams) => <ModuleRoutes {...routeParams} model={Roles} />} />

                                        <Route path={`${basePath}/permissions`} render={(routeParams) => <ModuleRoutes {...routeParams} model={Permissions} />} />

                                        <Route path={`${basePath}/users`} render={(routeParams) => <ModuleRoutes {...routeParams} model={Users} />} />

                                        <Route path={`${basePath}/questions`} render={(routeParams) => <ModuleRoutes {...routeParams} model={Questions} />} />

                                        <Route path={`${basePath}/lookup-types`} render={(routeParams) => <ModuleRoutes {...routeParams} model={LookupTypes} />} />

                                        <Route path={`${basePath}/process`} render={(routeParams) => <ModuleRoutes {...routeParams} model={Process} />} />

                                        <Route path={`${basePath}/forms`} render={(routeParams) => <ModuleRoutes {...routeParams} model={Forms} />} />

                                        <Route path={`${basePath}/actions`} render={(routeParams) => <ModuleRoutes {...routeParams} model={Actions} />} />

                                        <Route path={`${basePath}/steps`} render={(routeParams) => <ModuleRoutes {...routeParams} model={Steps} />} />

                                        {/* Routes Specific to Invoicing */}
                                        <Route path={`${basePath}/financial-years`} render={(routeParams) => <ModuleRoutes {...routeParams} model={FinancialYears} />} />

                                        <Route path={`${basePath}/branches`} render={(routeParams) => <ModuleRoutes {...routeParams} model={Branches} />} />

                                        <Route path={`${basePath}/departments`} render={(routeParams) => <ModuleRoutes {...routeParams} model={Departments} />} />

                                        <Route path={`${basePath}/invoice-numbers`} render={(routeParams) => <ModuleRoutes {...routeParams} model={InvoiceNumbers} />} />
                                        {/* Routes Specific to Invoicing Ends */}


                                        {/* KIA Specific Models */}


                                    </>
                                    :
                                    <>
                                    </>
                            }


                            <Route path={'/list/:id'} render={(routeParams) => <TaskList {...routeParams} />} />

                            <Route path={'/task/:step_id'} render={(routeParams) => <TaskDetail {...routeParams} />} />


                            <Route path={'/change-password'} render={(routeParams) => <ChangePassword {...routeParams} />} />

                            {/* Maintaining listing page for different status */}
                            {/* <TaskRoutes steps={tasks} list={ApprovalList} form={ApprovalForm} /> */}

                            {/* Only Admins will have access to the above routes */}

                            {/* Core Menus Ends */}

                            {/* </Switch> */}

                            {/* </Router> */}


                        </>}

            </GlobalHeader >
        </section >
    );
}


/**
 * Define route
 * 
 * @param {*} param0 
 * @returns 
 */
function DefineRoute({ menus, CustomComponents, CustomModels, callback }) {

    let history = useHistory();

    /**
     * 
     */
    const renderRoute = (menu, index, CustomComponents, CustomModels, callback) => {


        return (<>


            {/* <Switch> */}

            {/* If it has sub Menus */}
            {menu.sub_menus && menu.sub_menus.length ? <DefineRoute key={menu.id} menus={menu.sub_menus} CustomModels={CustomModels} CustomComponents={CustomComponents} callback={callback} /> : null}


            {menu && printRoute(menu, index, CustomComponents, CustomModels, callback)}

            {/* </Switch> */}


        </>)

        // if (menu.sub_menus && menu.sub_menus.length) {

        //     return

        // } else {

        //     return

        // }
    }



    function printRoute(menu, index, CustomComponents, CustomModels, callback) {

        // From the path we remove any query parameters that exist in the url
        //  
        let paths = menu.path.split('?');

        // Find the route path
        let path = paths[0];

        // let basePath = history.location.pathname.split('/')[1];

        let basePath = '/admin';

        console.log(basePath + path);

        return (
            <Route
                exact
                key={index}
                path={`${basePath}${path}`}
                render={(props) => {

                    return <MenuTemplate
                        menu={menu}

                        {...menu.attributes}

                        {...props}
                        CustomModels={CustomModels}
                        CustomComponents={CustomComponents}
                        callback={(props) => {
                            callback(props);
                        }} />;
                }}
            />
        );
    }




    return (<>

        {/* <Switch> */}

        {
            menus.map((menu, index) => {

                return renderRoute(menu, index, CustomComponents, CustomModels, callback)

            })
        }

        {/* </Switch> */}

    </>)

}