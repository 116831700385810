
import { Base } from './../';


class Outbox extends Base {

    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },

            {
                field: 'name',
                caption: 'Name',
            },

            {
                field: 'display_order',
                caption: 'Display Order',
            },
        ];
    }

    disableAddModal() {
        return true;
    }

    get getEndpoint() {
        return 'outbox';
    }

    get path() {
        return `outbox`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `outbox`;
    }

    get columns() {
        return [
            {
                field: 'from',
                caption: 'From',
            },
            {
                field: 'to',
                caption: 'To',
            },

            {
                field: 'created_at',
                caption: 'created_at',
            },

            {
                field: 'status',
                caption: 'Status',
            },
        ];
    }

}

export default Outbox;
